/*
	Descripción: Responsive
	Versión: 5.0
	Autor: GRUPO5.COM
	Autor WEB: http://www.grupo5.com/
*/

/*----Propiedades----*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*---imágenes para la web---*/

img {
  border: 0;
}
img {
  vertical-align: middle;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  min-width: 40px; /*operahack*/
}
.img-responsive.full {
  width: 100%;
}

.img-rounded {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

/*----Grid System--*/

/* Comenzar siempre por maquetación de la columna menor (xs) si no hay variaciones en resoluciones posteriores*/

/*
 xs < 768 (phones)
 s > = 768 (tablets)
 normal > = 992 (desktops)
 xl > = 1200 (large desktops)
*/

.contenedor {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1000px\9; /*IE8*/
}

.contenedor:before,
.contenedor:after {
  content: ' ';
  display: table;
}
.contenedor:after {
  clear: both;
}

@media (min-width: 768px) {
  .contenedor {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .contenedor {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .contenedor {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .contenedor {
    width: 1370px;
  }
}
/*@media (min-width: 1800px) {.contenedor {width: 1670px;}}*/
/*@media (min-width: 1500px) {.contenedor {width: 100%;}}*/ /*full*/

.contenedor-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.contenedor-full {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

/*fila*/
.fila {
  margin-right: -15px;
  margin-left: -15px;
}
.fila:before,
.fila:after {
  content: ' ';
  display: table;
}
.fila:after {
  clear: both;
}

/*fila igual altura*/
.fila-igual-altura {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*fila flex*/
.fila-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-marginpadding {
  margin-right: 0;
  margin-left: 0;
}
.no-marginpadding > .col,
.no-marginpadding > [class*='col'] {
  padding-right: 0;
  padding-left: 0;
}

/*al llevar ^ se aplica a los elmentos que comiencen por col
.fila-igual-altura > [class^='col'] {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
}*/

/*border*/
.borde-colder {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
  .borde-colder {
    border: none;
    border-top: 0px solid rgba(0, 0, 0, 0.16);
    padding-top: 10px;
  }
  .fila-igual-altura {
    display: inherit;
    display: inherit;
    display: inherit;
    display: inherit;
  }

  .fila-igual-altura > [class*='col'] {
    padding: 0px 15px;
  }
}

/*columanas*/
.col01-xl,
.col10-xl,
.col11-xl,
.col12-xl,
.col02-xl,
.col03-xl,
.col04-xl,
.col05-xl,
.col06-xl,
.col07-xl,
.col08-xl,
.col09-xl,
.col01,
.col10,
.col11,
.col12,
.col02,
.col03,
.col04,
.col05,
.col06,
.col07,
.col08,
.col09,
.col01-s,
.col10-s,
.col11-s,
.col12-s,
.col02-s,
.col03-s,
.col04-s,
.col05-s,
.col06-s,
.col07-s,
.col08-s,
.col09-s,
.col01-xs,
.col10-xs,
.col11-xs,
.col12-xs,
.col02-xs,
.col03-xs,
.col04-xs,
.col05-xs,
.col06-xs,
.col07-xs,
.col08-xs,
.col09-xs,
.col5col-xs,
.col5col-s,
.col5col,
.col5col-xl {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/*columanas flex*/
.col-flex,
.col01-flex,
.col10-flex,
.col11-flex,
.col12-flex,
.col02-flex,
.col03-flex,
.col04-flex,
.col05-flex,
.col06-flex,
.col07-flex,
.col08-flex,
.col09-flex,
.col-flex-auto,
.col-flex-xs,
.col01-flex-xs,
.col10-flex-xs,
.col11-flex-xs,
.col12-flex-xs,
.col02-flex-xs,
.col03-flex-xs,
.col04-flex-xs,
.col05-flex-xs,
.col06-flex-xs,
.col07-flex-xs,
.col08-flex-xs,
.col09-flex-xs,
.col-flex-s,
.col01-flex-s,
.col10-flex-s,
.col11-flex-s,
.col12-flex-s,
.col02-flex-s,
.col03-flex-s,
.col04-flex-s,
.col05-flex-s,
.col06-flex-s,
.col07-flex-s,
.col08-flex-s,
.col09-flex-s,
.col-flex-xl,
.col01-flex-xl,
.col10-flex-xl,
.col11-flex-xl,
.col12-flex-xl,
.col02-flex-xl,
.col03-flex-xl,
.col04-flex-xl,
.col05-flex-xl,
.col06-flex-xl,
.col07-flex-xl,
.col08-flex-xl,
.col09-flex-xl,
.col-flex-xxl,
.col01-flex-xxl,
.col10-flex-xxl,
.col11-flex-xxl,
.col12-flex-xxl,
.col02-flex-xxl,
.col03-flex-xxl,
.col04-flex-xxl,
.col05-flex-xxl,
.col06-flex-xxl,
.col07-flex-xxl,
.col08-flex-xxl,
.col09-flex-xxl {
  position: relative;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 360px) {
  .col01-xs,
  .col10-xs,
  .col11-xs,
  .col12-xs,
  .col02-xs,
  .col03-xs,
  .col04-xs,
  .col05-xs,
  .col06-xs,
  .col07-xs,
  .col08-xs,
  .col09-xs {
    float: left;
    z-index: 1;
  }
  .col12-xs {
    width: 100%;
  }
  .col11-xs {
    width: 91.66666667%;
  }
  .col10-xs {
    width: 83.33333333%;
  }
  .col09-xs {
    width: 75%;
  }
  .col08-xs {
    width: 66.66666667%;
  }
  .col07-xs {
    width: 58.33333333%;
  }
  .col06-xs {
    width: 50%;
  }
  .col05-xs {
    width: 41.66666667%;
  }
  .col04-xs {
    width: 33.33333333%;
  }
  .col03-xs {
    width: 25%;
  }
  .col02-xs {
    width: 16.66666667%;
  }
  .col01-xs {
    width: 8.33333333%;
  }

  /*columnas flex*/
  .col01-flex-xs {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col02-flex-xs {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col03-flex-xs {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col04-flex-xs {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col05-flex-xs {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col06-flex-xs {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col07-flex-xs {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col08-flex-xs {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col09-flex-xs {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col10-flex-xs {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col11-flex-xs {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col12-flex-xs {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*empujar hacia la derecha*/
  .col12-xs-pull {
    right: 100%;
  }
  .col11-xs-pull {
    right: 91.66666667%;
  }
  .col10-xs-pull {
    right: 83.33333333%;
  }
  .col09-xs-pull {
    right: 75%;
  }
  .col08-xs-pull {
    right: 66.66666667%;
  }
  .col07-xs-pull {
    right: 58.33333333%;
  }
  .col06-xs-pull {
    right: 50%;
  }
  .col05-xs-pull {
    right: 41.66666667%;
  }
  .col04-xs-pull {
    right: 33.33333333%;
  }
  .col03-xs-pull {
    right: 25%;
  }
  .col02-xs-pull {
    right: 16.66666667%;
  }
  .col01-xs-pull {
    right: 8.33333333%;
  }
  .col00-xs-pull {
    right: auto;
  }

  /*tirar hacia la izquierda*/
  .col12-xs-push {
    left: 100%;
  }
  .col11-xs-push {
    left: 91.66666667%;
  }
  .col10-xs-push {
    left: 83.33333333%;
  }
  .col09-xs-push {
    left: 75%;
  }
  .col08-xs-push {
    left: 66.66666667%;
  }
  .col07-xs-push {
    left: 58.33333333%;
  }
  .col06-xs-push {
    left: 50%;
  }
  .col05-xs-push {
    left: 41.66666667%;
  }
  .col04-xs-push {
    left: 33.33333333%;
  }
  .col03-xs-push {
    left: 25%;
  }
  .col02-xs-push {
    left: 16.66666667%;
  }
  .col01-xs-push {
    left: 8.33333333%;
  }
  .col00-xs-push {
    left: auto;
  }

  /*desplazar cualquier columna hacia la derecha*/
  .col12-xs-offset {
    margin-left: 100%;
  }
  .col11-xs-offset {
    margin-left: 91.66666667%;
  }
  .col10-xs-offset {
    margin-left: 83.33333333%;
  }
  .col09-xs-offset {
    margin-left: 75%;
  }
  .col08-xs-offset {
    margin-left: 66.66666667%;
  }
  .col07-xs-offset {
    margin-left: 58.33333333%;
  }
  .col06-xs-offset {
    margin-left: 50%;
  }
  .col05-xs-offset {
    margin-left: 41.66666667%;
  }
  .col04-xs-offset {
    margin-left: 33.33333333%;
  }
  .col03-xs-offset {
    margin-left: 25%;
  }
  .col02-xs-offset {
    margin-left: 16.66666667%;
  }
  .col01-xs-offset {
    margin-left: 8.33333333%;
  }
  .col00-xs-offset {
    margin-left: 0;
  }

  /*altura de columna*/
  .col-xs-height-100 {
    height: 100%;
  }
  .col-xs-height-auto {
    height: auto;
  }
  .col-xs-height-80px {
    height: 80px;
  }
  .col-xs-height-90px {
    height: 90px;
  }
  .col-xs-height-100px {
    height: 100px;
  }
  .col-xs-height-150px {
    height: 150px;
  }
  .col-xs-height-200px {
    height: 200px;
  }
  .col-xs-height-250px {
    height: 250px;
  }
  .col-xs-height-300px {
    height: 300px;
  }
  .col-xs-height-350px {
    height: 350px;
  }
  .col-xs-height-400px {
    height: 400px;
  }
  .col-xs-height-450px {
    height: 450px;
  }
  .col-xs-height-500px {
    height: 500px;
  }
  .col-xs-height-550px {
    height: 550px;
  }
  .col-xs-height-600px {
    height: 600px;
  }
  .col-xs-height-650px {
    height: 650px;
  }
  .col-xs-height-700px {
    height: 700px;
  }

  /*5columnas*/
  .col5col-xs {
    width: 20%;
    float: left;
  }

  /*5columnas flex*/
  .col5col-flex {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .col01-s,
  .col10-s,
  .col11-s,
  .col12-s,
  .col02-s,
  .col03-s,
  .col04-s,
  .col05-s,
  .col06-s,
  .col07-s,
  .col08-s,
  .col09-s {
    float: left;
    z-index: 1;
  }
  .col12-s {
    width: 100%;
  }
  .col11-s {
    width: 91.66666667%;
  }
  .col10-s {
    width: 83.33333333%;
  }
  .col09-s {
    width: 75%;
  }
  .col08-s {
    width: 66.66666667%;
  }
  .col07-s {
    width: 58.33333333%;
  }
  .col06-s {
    width: 50%;
  }
  .col05-s {
    width: 41.66666667%;
  }
  .col04-s {
    width: 33.33333333%;
  }
  .col03-s {
    width: 25%;
  }
  .col02-s {
    width: 16.66666667%;
  }
  .col01-s {
    width: 8.33333333%;
  }

  /*columnas flex*/
  .col01-flex-s {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col02-flex-s {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col03-flex-s {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col04-flex-s {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col05-flex-s {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col06-flex-s {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col07-flex-s {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col08-flex-s {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col09-flex-s {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col10-flex-s {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col11-flex-s {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col12-flex-s {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*pull*/
  .col12-s-pull {
    right: 100%;
  }
  .col11-s-pull {
    right: 91.66666667%;
  }
  .col10-s-pull {
    right: 83.33333333%;
  }
  .col09-s-pull {
    right: 75%;
  }
  .col08-s-pull {
    right: 66.66666667%;
  }
  .col07-s-pull {
    right: 58.33333333%;
  }
  .col06-s-pull {
    right: 50%;
  }
  .col05-s-pull {
    right: 41.66666667%;
  }
  .col04-s-pull {
    right: 33.33333333%;
  }
  .col03-s-pull {
    right: 25%;
  }
  .col02-s-pull {
    right: 16.66666667%;
  }
  .col01-s-pull {
    right: 8.33333333%;
  }
  .col00-s-pull {
    right: auto;
  }

  .col12-s-push {
    left: 100%;
  }
  .col11-s-push {
    left: 91.66666667%;
  }
  .col10-s-push {
    left: 83.33333333%;
  }
  .col09-s-push {
    left: 75%;
  }
  .col08-s-push {
    left: 66.66666667%;
  }
  .col07-s-push {
    left: 58.33333333%;
  }
  .col06-s-push {
    left: 50%;
  }
  .col05-s-push {
    left: 41.66666667%;
  }
  .col04-s-push {
    left: 33.33333333%;
  }
  .col03-s-push {
    left: 25%;
  }
  .col02-s-push {
    left: 16.66666667%;
  }
  .col01-s-push {
    left: 8.33333333%;
  }
  .col00-s-push {
    left: auto;
  }

  .col12-s-offset {
    margin-left: 100%;
  }
  .col11-s-offset {
    margin-left: 91.66666667%;
  }
  .col10-s-offset {
    margin-left: 83.33333333%;
  }
  .col09-s-offset {
    margin-left: 75%;
  }
  .col08-s-offset {
    margin-left: 66.66666667%;
  }
  .col07-s-offset {
    margin-left: 58.33333333%;
  }
  .col06-s-offset {
    margin-left: 50%;
  }
  .col05-s-offset {
    margin-left: 41.66666667%;
  }
  .col04-s-offset {
    margin-left: 33.33333333%;
  }
  .col03-s-offset {
    margin-left: 25%;
  }
  .col02-s-offset {
    margin-left: 16.66666667%;
  }
  .col01-s-offset {
    margin-left: 8.33333333%;
  }
  .col00-s-offset {
    margin-left: 0;
  }

  /*altura de columna*/
  .col-s-height-100 {
    height: 100%;
  }
  .col-s-height-auto {
    height: auto;
  }
  .col-s-height-80px {
    height: 80px;
  }
  .col-s-height-90px {
    height: 90px;
  }
  .col-s-height-100px {
    height: 100px;
  }
  .col-s-height-150px {
    height: 150px;
  }
  .col-s-height-200px {
    height: 200px;
  }
  .col-s-height-250px {
    height: 250px;
  }
  .col-s-height-300px {
    height: 300px;
  }
  .col-s-height-350px {
    height: 350px;
  }
  .col-s-height-400px {
    height: 400px;
  }
  .col-s-height-450px {
    height: 450px;
  }
  .col-s-height-500px {
    height: 500px;
  }
  .col-s-height-550px {
    height: 550px;
  }
  .col-s-height-600px {
    height: 600px;
  }
  .col-s-height-650px {
    height: 650px;
  }
  .col-s-height-700px {
    height: 700px;
  }

  /*5columnas*/
  .col5col-s {
    width: 20%;
    float: left;
  }

  /*5columnas flex*/
  .col5col-flex {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col01,
  .col10,
  .col11,
  .col12,
  .col02,
  .col03,
  .col04,
  .col05,
  .col06,
  .col07,
  .col08,
  .col09 {
    float: left;
  }
  .col12 {
    width: 100%;
  }
  .col11 {
    width: 91.66666667%;
  }
  .col10 {
    width: 83.33333333%;
  }
  .col09 {
    width: 75%;
  }
  .col08 {
    width: 66.66666667%;
  }
  .col07 {
    width: 58.33333333%;
  }
  .col06 {
    width: 50%;
  }
  .col05 {
    width: 41.66666667%;
  }
  .col04 {
    width: 33.33333333%;
  }
  .col03 {
    width: 25%;
  }
  .col02 {
    width: 16.66666667%;
  }
  .col01 {
    width: 8.33333333%;
  }

  /*columnas flex*/
  .col01-flex {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col02-flex {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col03-flex {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col04-flex {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col05-flex {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col06-flex {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col07-flex {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col08-flex {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col09-flex {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col10-flex {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col11-flex {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col12-flex {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*pull*/
  .col12-pull {
    right: 100%;
  }
  .col11-pull {
    right: 91.66666667%;
  }
  .col10-pull {
    right: 83.33333333%;
  }
  .col09-pull {
    right: 75%;
  }
  .col08-pull {
    right: 66.66666667%;
  }
  .col07-pull {
    right: 58.33333333%;
  }
  .col06-pull {
    right: 50%;
  }
  .col05-pull {
    right: 41.66666667%;
  }
  .col04-pull {
    right: 33.33333333%;
  }
  .col03-pull {
    right: 25%;
  }
  .col02-pull {
    right: 16.66666667%;
  }
  .col01-pull {
    right: 8.33333333%;
  }
  .col00-pull {
    right: auto;
  }

  .col12-push {
    left: 100%;
  }
  .col11-push {
    left: 91.66666667%;
  }
  .col10-push {
    left: 83.33333333%;
  }
  .col09-push {
    left: 75%;
  }
  .col08-push {
    left: 66.66666667%;
  }
  .col07-push {
    left: 58.33333333%;
  }
  .col06-push {
    left: 50%;
  }
  .col05-push {
    left: 41.66666667%;
  }
  .col04-push {
    left: 33.33333333%;
  }
  .col03-push {
    left: 25%;
  }
  .col02-push {
    left: 16.66666667%;
  }
  .col01-push {
    left: 8.33333333%;
  }
  .col00-push {
    left: auto;
  }

  .col12-offset {
    margin-left: 100%;
  }
  .col11-offset {
    margin-left: 91.66666667%;
  }
  .col10-offset {
    margin-left: 83.33333333%;
  }
  .col09-offset {
    margin-left: 75%;
  }
  .col08-offset {
    margin-left: 66.66666667%;
  }
  .col07-offset {
    margin-left: 58.33333333%;
  }
  .col06-offset {
    margin-left: 50%;
  }
  .col05-offset {
    margin-left: 41.66666667%;
  }
  .col04-offset {
    margin-left: 33.33333333%;
  }
  .col03-offset {
    margin-left: 25%;
  }
  .col02-offset {
    margin-left: 16.66666667%;
  }
  .col01-offset {
    margin-left: 8.33333333%;
  }
  .col00-offset {
    margin-left: 0;
  }

  /*altura de columna*/
  .col-height-100 {
    height: 100%;
  }
  .col-height-auto {
    height: auto;
  }
  .col-height-80px {
    height: 80px;
  }
  .col-height-90px {
    height: 90px;
  }
  .col-height-100px {
    height: 100px;
  }
  .col-height-150px {
    height: 150px;
  }
  .col-height-200px {
    height: 200px;
  }
  .col-height-250px {
    height: 250px;
  }
  .col-height-300px {
    height: 300px;
  }
  .col-height-350px {
    height: 350px;
  }
  .col-height-400px {
    height: 400px;
  }
  .col-height-450px {
    height: 450px;
  }
  .col-height-500px {
    height: 500px;
  }
  .col-height-550px {
    height: 550px;
  }
  .col-height-600px {
    height: 600px;
  }
  .col-height-650px {
    height: 650px;
  }
  .col-height-700px {
    height: 700px;
  }

  /*5columnas*/
  .col5col {
    width: 20%;
    float: left;
  }

  /*5columnas flex*/
  .col5col-flex {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (max-width: 992px) {
  .separatopbot2 {
    margin: 30px 0;
  }
  .separabot2 {
    margin-bottom: 30px;
  }
  .separatop2 {
    margin-top: 30px;
  }

  .sepcolbot {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .sepcolbot2 {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .col01-xl,
  .col10-xl,
  .col11-xl,
  .col12-xl,
  .col02-xl,
  .col03-xl,
  .col04-xl,
  .col05-xl,
  .col06-xl,
  .col07-xl,
  .col08-xl,
  .col09-xl {
    float: left;
  }
  .col12-xl {
    width: 100%;
  }
  .col11-xl {
    width: 91.66666667%;
  }
  .col10-xl {
    width: 83.33333333%;
  }
  .col09-xl {
    width: 75%;
  }
  .col08-xl {
    width: 66.66666667%;
  }
  .col07-xl {
    width: 58.33333333%;
  }
  .col06-xl {
    width: 50%;
  }
  .col05-xl {
    width: 41.66666667%;
  }
  .col04-xl {
    width: 33.33333333%;
  }
  .col03-xl {
    width: 25%;
  }
  .col02-xl {
    width: 16.66666667%;
  }
  .col01-xl {
    width: 8.33333333%;
  }

  /*columnas flex*/
  .col01-flex-xl {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col02-flex-xl {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col03-flex-xl {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col04-flex-xl {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col05-flex-xl {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col06-flex-xl {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col07-flex-xl {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col08-flex-xl {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col09-flex-xl {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col10-flex-xl {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col11-flex-xl {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col12-flex-xl {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*pull*/
  .col12-xl-pull {
    right: 100%;
  }
  .col11-xl-pull {
    right: 91.66666667%;
  }
  .col10-xl-pull {
    right: 83.33333333%;
  }
  .col09-xl-pull {
    right: 75%;
  }
  .col08-xl-pull {
    right: 66.66666667%;
  }
  .col07-xl-pull {
    right: 58.33333333%;
  }
  .col06-xl-pull {
    right: 50%;
  }
  .col05-xl-pull {
    right: 41.66666667%;
  }
  .col04-xl-pull {
    right: 33.33333333%;
  }
  .col03-xl-pull {
    right: 25%;
  }
  .col02-xl-pull {
    right: 16.66666667%;
  }
  .col01-xl-pull {
    right: 8.33333333%;
  }
  .col00-xl-pull {
    right: auto;
  }

  .col12-xl-push {
    left: 100%;
  }
  .col11-xl-push {
    left: 91.66666667%;
  }
  .col10-xl-push {
    left: 83.33333333%;
  }
  .col09-xl-push {
    left: 75%;
  }
  .col08-xl-push {
    left: 66.66666667%;
  }
  .col07-xl-push {
    left: 58.33333333%;
  }
  .col06-xl-push {
    left: 50%;
  }
  .col05-xl-push {
    left: 41.66666667%;
  }
  .col04-xl-push {
    left: 33.33333333%;
  }
  .col03-xl-push {
    left: 25%;
  }
  .col02-xl-push {
    left: 16.66666667%;
  }
  .col01-xl-push {
    left: 8.33333333%;
  }
  .col00-xl-push {
    left: auto;
  }

  .col12-xl-offset {
    margin-left: 100%;
  }
  .col11-xl-offset {
    margin-left: 91.66666667%;
  }
  .col10-xl-offset {
    margin-left: 83.33333333%;
  }
  .col09-xl-offset {
    margin-left: 75%;
  }
  .col08-xl-offset {
    margin-left: 66.66666667%;
  }
  .col07-xl-offset {
    margin-left: 58.33333333%;
  }
  .col06-xl-offset {
    margin-left: 50%;
  }
  .col05-xl-offset {
    margin-left: 41.66666667%;
  }
  .col04-xl-offset {
    margin-left: 33.33333333%;
  }
  .col03-xl-offset {
    margin-left: 25%;
  }
  .col02-xl-offset {
    margin-left: 16.66666667%;
  }
  .col01-xl-offset {
    margin-left: 8.33333333%;
  }
  .col00-xl-offset {
    margin-left: 0;
  }

  /*altura de columna*/
  .col-xl-height-100 {
    height: 100%;
  }
  .col-xl-height-auto {
    height: auto;
  }
  .col-xl-height-80px {
    height: 80px;
  }
  .col-xl-height-90px {
    height: 90px;
  }
  .col-xl-height-100px {
    height: 100px;
  }
  .col-xl-height-150px {
    height: 150px;
  }
  .col-xl-height-200px {
    height: 200px;
  }
  .col-xl-height-250px {
    height: 250px;
  }
  .col-xl-height-300px {
    height: 300px;
  }
  .col-xl-height-350px {
    height: 350px;
  }
  .col-xl-height-400px {
    height: 400px;
  }
  .col-xl-height-450px {
    height: 450px;
  }
  .col-xl-height-500px {
    height: 500px;
  }
  .col-xl-height-550px {
    height: 550px;
  }
  .col-xl-height-600px {
    height: 600px;
  }
  .col-xl-height-650px {
    height: 650px;
  }
  .col-xl-height-700px {
    height: 700px;
  }

  /*5columnas*/
  .col5col-xl {
    width: 20%;
    float: left;
  }

  /*5columnas flex*/
  .col5col-flex {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/*---responsive - Escritorios muy grandes--- */
@media (min-width: 1600px) {
  .col01-xxl,
  .col02-xxl,
  .col03-xxl,
  .col04-xxl,
  .col05-xxl,
  .col06-xxl,
  .col07-xxl,
  .col08-xxl,
  .col09-xxl,
  .col10-xxl,
  .col11-xxl,
  .col12-xxl {
    float: left;
  }
  .col12-xl {
    width: 100%;
  }
  .col11-xxl {
    width: 91.66666667%;
  }
  .col10-xxl {
    width: 83.33333333%;
  }
  .col09-xxl {
    width: 75%;
  }
  .col08-xxl {
    width: 66.66666667%;
  }
  .col07-xxl {
    width: 58.33333333%;
  }
  .col06-xxl {
    width: 50%;
  }
  .col05-xxl {
    width: 41.66666667%;
  }
  .col04-xxl {
    width: 33.33333333%;
  }
  .col03-xxl {
    width: 25%;
  }
  .col02-xxl {
    width: 16.66666667%;
  }
  .col01-xxl {
    width: 8.33333333%;
  }

  /*columnas flex*/
  .col01-flex-xxl {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col02-flex-xxl {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col03-flex-xxl {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col04-flex-xxl {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col05-flex-xxl {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col06-flex-xxl {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col07-flex-xxl {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col08-flex-xxl {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col09-flex-xxl {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col10-flex-xxl {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col11-flex-xxl {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col12-flex-xxl {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /*pull*/
  .col12-xxl-pull {
    right: 100%;
  }
  .col11-xxl-pull {
    right: 91.66666667%;
  }
  .col10-xxl-pull {
    right: 83.33333333%;
  }
  .col09-xxl-pull {
    right: 75%;
  }
  .col08-xxl-pull {
    right: 66.66666667%;
  }
  .col07-xxl-pull {
    right: 58.33333333%;
  }
  .col06-xxl-pull {
    right: 50%;
  }
  .col05-xxl-pull {
    right: 41.66666667%;
  }
  .col04-xxl-pull {
    right: 33.33333333%;
  }
  .col03-xxl-pull {
    right: 25%;
  }
  .col02-xxl-pull {
    right: 16.66666667%;
  }
  .col01-xxl-pull {
    right: 8.33333333%;
  }
  .col00-xxl-pull {
    right: auto;
  }

  .col12-xxl-push {
    left: 100%;
  }
  .col11-xxl-push {
    left: 91.66666667%;
  }
  .col10-xxl-push {
    left: 83.33333333%;
  }
  .col09-xxl-push {
    left: 75%;
  }
  .col08-xxl-push {
    left: 66.66666667%;
  }
  .col07-xxl-push {
    left: 58.33333333%;
  }
  .col06-xxl-push {
    left: 50%;
  }
  .col05-xxl-push {
    left: 41.66666667%;
  }
  .col04-xxl-push {
    left: 33.33333333%;
  }
  .col03-xxl-push {
    left: 25%;
  }
  .col02-xxl-push {
    left: 16.66666667%;
  }
  .col01-xxl-push {
    left: 8.33333333%;
  }
  .col00-xxl-push {
    left: auto;
  }

  .col12-xxl-offset {
    margin-left: 100%;
  }
  .col11-xxl-offset {
    margin-left: 91.66666667%;
  }
  .col10-xxl-offset {
    margin-left: 83.33333333%;
  }
  .col09-xxl-offset {
    margin-left: 75%;
  }
  .col08-xxl-offset {
    margin-left: 66.66666667%;
  }
  .col07-xxl-offset {
    margin-left: 58.33333333%;
  }
  .col06-xxl-offset {
    margin-left: 50%;
  }
  .col05-xxl-offset {
    margin-left: 41.66666667%;
  }
  .col04-xxl-offset {
    margin-left: 33.33333333%;
  }
  .col03-xxl-offset {
    margin-left: 25%;
  }
  .col02-xxl-offset {
    margin-left: 16.66666667%;
  }
  .col01-xxl-offset {
    margin-left: 8.33333333%;
  }
  .col00-xxl-offset {
    margin-left: 0;
  }

  /*altura de columna*/
  .col-xxl-height-100 {
    height: 100%;
  }
  .col-xxl-height-auto {
    height: auto;
  }
  .col-xxl-height-80px {
    height: 80px;
  }
  .col-xxl-height-90px {
    height: 90px;
  }
  .col-xxl-height-100px {
    height: 100px;
  }
  .col-xxl-height-150px {
    height: 150px;
  }
  .col-xxl-height-200px {
    height: 200px;
  }
  .col-xxl-height-250px {
    height: 250px;
  }
  .col-xxl-height-300px {
    height: 300px;
  }
  .col-xxl-height-350px {
    height: 350px;
  }
  .col-xxl-height-400px {
    height: 400px;
  }
  .col-xxl-height-450px {
    height: 450px;
  }
  .col-xxl-height-500px {
    height: 500px;
  }
  .col-xxl-height-550px {
    height: 550px;
  }
  .col-xxl-height-600px {
    height: 600px;
  }
  .col-xxl-height-650px {
    height: 650px;
  }
  .col-xxl-height-700px {
    height: 700px;
  }

  /*5columnas*/
  .col5col-xxl {
    width: 20%;
    float: left;
  }

  /*5columnas flex*/
  .col5col-flex {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/*___________MOSTRAR: ESCRITORIO / TABLET / TELEFONO___________*/

.escritorio {
  visibility: hidden;
  display: none;
}
.tableta {
  visibility: hidden;
  display: none;
}
.telefono {
  visibility: hidden;
  display: none;
}

/*ESCRITORIO Y MAS*/
@media (min-width: 992px) {
  .escritorio {
    visibility: visible;
    display: inherit;
  }
}

/*TABLET*/
@media (min-width: 768px) and (max-width: 991px) {
  .tableta {
    visibility: visible;
    display: inherit;
  }
}

/*MOVIL*/
@media (min-width: 0px) and (max-width: 767px) {
  .telefono {
    visibility: visible;
    display: inherit;
  }
}

/*____________CAJAS FLEXIBLES___________*/

.flexbox3,
.flexbox2,
.flexbox4,
.flexbox6 {
  padding: 0% 0% 0;
  text-align: justify;
}

.flexbox3:after,
.flexbox2:after,
.flexbox4:after,
.flexbox6:after {
  content: '';
  display: inline-block;
  width: 100%;
}

.flexbox3 .zonaflex,
.flexbox3 .gap {
  display: inline-block;
  width: 32%;
  margin: 0px;
}

.flexbox2 .zonaflex,
.flexbox2 .gap {
  display: inline-block;
  width: 48%;
  margin: 0px;
}

.flexbox4 .zonaflex,
.flexbox4 .gap {
  display: inline-block;
  width: 24%;
  margin: 0px;
  background: ;
}

.flexbox6 .zonaflex,
.flexbox6 .gap {
  display: inline-block;
  width: 15%;
  margin: 0px;
}

.flexbox3 .zonaflex,
.flexbox2 .zonaflex,
.flexbox4 .zonaflex,
.flexbox6 .zonaflex {
  text-align: left;
  margin-bottom: 0px;
  vertical-align: top;
}

.flexbox3,
.flexbox4,
.flexbox2,
.flexbox6 {
  /*hace que se ponga el fondo al 100% pero sólo en Chrome*/
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -webkit-align-content: flex-start;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  /*añadido por mi*/
  /* flex-wrap: wrap;
flex-grow: 1;
 display: flex;
 
 display: -ms-flexbox;
 display: -webkit-flex;
 display: flex;*/
}

@media (max-width: 1100px) {
  .flexbox6 .zonaflex,
  .flexbox6 .gap {
    width: 32%;
  }
}

@media (max-width: 991px) /*768*/ {
  .flexbox3 .zonaflex,
  .flexbox3 .gap,
  .flexbox2 .zonaflex,
  .flexbox2 .gap,
  .flexbox4 .zonaflex,
  .flexbox4 .gap,
  .flexbox6 .zonaflex,
  .flexbox6 .gap {
    width: 47%;
  } /*47%*/
}

@media (max-width: 520px) {
  .flexbox3 .zonaflex,
  .flexbox3 .gap,
  .flexbox2 .zonaflex,
  .flexbox2 .gap,
  .flexbox4 .zonaflex,
  .flexbox4 .gap,
  .flexbox6 .zonaflex,
  .flexbox6 .gap {
    width: 47%;
  }
}
