/* ###########################################################################
######
######
######
######  CUSTOM FORMS 
######
######
######
###########################################################################*/

/* ###########################################################################
######
######  GENERAL
######
###########################################################################*/

textarea:focus,
input:focus {
  outline: 0;
}

.formulario {
  font: normal 1em Arial, Helvetica, sans-serif;
  color: #666;
  background: #fff;
  border: 1px solid #ccc;
  padding: 15px 12px;
  width: 100%;
  margin-bottom: 8px;

  display: inline-block;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-bottom: 5px;
}

.formulario:focus {
  border-color: #7c7c7c;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(124, 124, 124, 0.075),
    0 0 8px rgba(124, 124, 124, 0.6);
  box-shadow: inset 0 1px 1px rgba(124, 124, 124, 0.075),
    0 0 8px rgba(124, 124, 124, 0.6);
}
.formulario::-moz-placeholder {
  color: #666;
  opacity: 1;
}
.formulario:-ms-input-placeholder {
  color: #666;
}
.formulario::-webkit-input-placeholder {
  color: #666;
}

.formulario.btnizq {
  padding-left: 60px;
}
.formulario.btnder {
  padding-right: 60px;
}

.formulario.ok {
  border: 1px solid #adadad;
  background-position: 100% 50%;
}
.formulario.ko {
  border: 1px solid #d9534f;
  background: #fff;
  background-position: 100% 50%;
  color: #d9534f;
}

.formulario.obligatorio {
  border: 0 0 1px 0 solid #f59c1a;
  background-position: 100% 50%;
}
.formulario:disabled {
  color: #666666;
  background-position: 100% 50%;
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
}

label.formulario {
  display: inline-block;
  position: relative;
  /*width:140px;*/
  /*width:18%;*/
  border: 0;
  padding: 12px 5px;
  color: #333;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 2px;
  vertical-align: middle;
  vertical-align: top;
}

.formulario.min {
  max-width: 200px;
  width: 170px;
}
.formulario.med {
  max-width: 300px;
  width: 250px;
}
.formulario.auto {
  width: 70%;
}

label.formulario img {
  padding: 0px;
}

.select_min {
  min-width: 40px;
  width: auto;
  margin: 0 15px 15px 0;
  display: inline-block;
}

/*Asterisco obligatorio*/
.color-asterisco {
  fill: #ffbe57;
}
input.obligatorio[type='text'],
input.obligatorio[type='password'],
textarea.obligatorio,
select.obligatorio {
  background: #fff url('../images/asterisco.svg') no-repeat;
  background-size: 8px 8px;
  background-position: right 3px top 3px !important;
}

@media (max-width: 670px) {
  label.formulario {
    width: 100%; /*padding:15px 5px 10px 5px;*/
  }
  .formulario {
    margin-bottom: 15px;
  }
}

@media (max-width: 560px) {
  .formulario.auto {
    width: 100%;
  }
  .select_min {
    width: 100%;
  }
}

/* ###########################################################################
######
######  TEXT AREA 
######
###########################################################################*/

textarea {
  overflow: auto; /*scroll auto*/
  resize: none;
}

.textarea {
  line-height: 130%;
}

input[type='textarea'].formulario {
  background-position: right bottom;
}

/* ###########################################################################
######
######  FILE
######
###########################################################################*/
/*input[type=select-one].formulario {*/
input[type='file'].formulario {
  border: 0px solid #ccc;
  /*opacity: 0;
	-moz-opacity: 0;
	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);*/
}

/* ###########################################################################
#####
#####
#####    RADIO BUTTON
#####
#####
###########################################################################*/
input[type='radio'].formulario {
  clear: both;
  width: auto;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

/* ###########################################################################
#####
#####
#####    CHECK BOX
#####
#####
###########################################################################*/
input[type='checkbox'].chkbox + label {
  margin-right: 5px;
  /*padding-right:20px;*/
  width: 15px;
  height: 16px;
  cursor: pointer;
  width: auto;
  float: left;
  padding: 0;
}

input[type='checkbox'] {
  width: 15px;
  height: 16px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  *overflow: hidden;
  vertical-align: middle;
  margin: 8px 0;
}

.checkbox input[type='checkbox'] {
  margin: 0px;
}

.alert input[type='checkbox'] {
  outline-style: auto;
  outline-color: #ff9c00;
  height: 15px;
  outline-width: 1px;
}

/*--SWITCH--*/

.input-control.switch input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
}
.input-control.switch .caption {
  margin: 0 5px;
}

/*.input-control.switch .check {
  position: relative;
  width: 30px;
  height: 18px;
  outline: 0px #a6a6a6 solid;
  border: 1px #fff solid;
  cursor: pointer;
  background: #a6a6a6;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}
.input-control.switch .check:after {
  position: absolute;
  left: -1px;
  top: -1px;
  display: block;
  content: "";
  width: 12px;
  height: 18px;
  outline: 2px #6e6e6e solid;
  border: 1px #6e6e6e solid;
  cursor: pointer;
  background: #484848;
  z-index: 2;
}*/

.input-control.switch .check {
  position: relative;
  width: 35px;
  height: 18px;
  /*outline: 0px #a6a6a6 solid;
  border: 1px #fff solid;*/
  cursor: pointer;
  background: #a6a6a6;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
}

.input-control.switch .check:after {
  position: absolute;
  left: -1px;
  top: -1px;
  display: block;
  content: '';
  margin: 2px 2px 0 2px;
  width: 18px;
  height: 16px;
  /*outline: 2px #6e6e6e solid;
  border: 1px #6e6e6e solid;*/
  cursor: pointer;
  background: #fff;
  z-index: 2;
  border-radius: 40px;
}

.input-control.switch input[type='checkbox']:focus ~ .check,
.input-control.switch input[type='radio']:focus ~ .check {
  outline: 1px #999 dotted !important;
}

.input-control.switch input[type='checkbox']:checked ~ .check {
  background: #ff8b00;
}
.input-control.switch input[type='checkbox']:checked ~ .check:after {
  left: auto;
  right: -1px;
}

.input-control.switch input[type='checkbox']:disabled ~ .check {
  background-color: #e6e6e6;
  border-color: #ffffff;
}

.input-control.switch input[type='checkbox']:disabled ~ .check:after {
  background-color: #8a8a8a;
  outline-color: #8a8a8a;
  border-color: #8a8a8a;
}

/*Checkbox decorado ------------------------------ */

.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark-check {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark-check {
  background-color: #ff8b00; /*COLOR DESTACADO*/
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-check:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark-check:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark-check:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Checkbox decorado fin ------------------------------ */

/*Radiobuton decorado -------------------------------- */
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark-radio {
  background-color: #ff8b00; /*COLOR DESTACADO*/
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/*Radiobuton decorado fin ---------------------------- */

/* ###########################################################################
#####
#####
#####  BOTONES
#####
#####
###########################################################################*/

input[type='button'].formulario,
input[type='submit'].formulario {
  background-color: #86c102; /*new*/
  color: #fff;
  width: auto;
  text-decoration: none;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-indent: 2px;
  padding: 17px 45px;
  margin-bottom: 0;

  -webkit-appearance: button; /*safari*/
}

input[type='button'].formulario.full,
input[type='submit'].formulario.full {
  width: 100%;
}

input[type='button'].formulario:disabled,
input[type='submit'].formulario:disabled {
  color: #919191;
  background: #eeeeee;
  text-shadow: 1px 1px 1px #aaaaaa;
  border: 1px solid #aaaaaa;
}

@media (max-width: 560px) {
  input[type='button'].formulario,
  input[type='submit'].formulario {
    width: 100%;
  }
  input[type='button'].formulario.auto,
  input[type='submit'].formulario.auto {
    width: auto;
  }
}

/* ###########################################################################
#####
#####
#####  INPUT CON ESTILOS CHECKBOX - RADIO - SWITCH
#####
#####
###########################################################################*/

/* Radio Box */

.rdiobox {
  font-weight: normal;
  position: relative;
  display: inline-block;
  line-height: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.rdiobox span {
  line-height: 150%;
  display: block;
  overflow: hidden;
  padding-left: 4px;
}

.rdiobox span:before,
.rdiobox span:after {
  line-height: 20px;
  position: absolute;
}

.rdiobox span:before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 50px;
  top: 2px;
  left: 0;
}
.rdiobox span:after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #cbcbcb;
  border-radius: 50px;
  top: 7px;
  left: 5px;
  display: none;
}

.rdiobox input[type='radio'] {
  opacity: 0;
  margin: 5px 10px 0px 0px;
  float: left;
}

.rdiobox input[type='radio']:checked + span:before {
  border-color: #cbcbcb;
}

.rdiobox input[type='radio']:checked + span:after {
  display: block;
}
.rdiobox input[type='radio'][disabled] + span,
.rdiobox input[type='radio'][disabled] + span:before,
.rdiobox input[type='radio'][disabled] + span:after {
  opacity: 0.75;
}

/* ###########################################################################
#####
#####
#####  INPUT CON BOTÓN
#####
#####
###########################################################################*/

/*newsletter*/
.subscribe-mail-wrapper {
  display: -ms-flexbox;
  display: flex;
  margin: 8px auto 0px auto;
  text-align: center;
}
.subscribe-mail-wrapper input {
  width: 300px;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 45px;
  padding: 10px 15px;
  font-size: 0.9375em;
}
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  padding: 1.052em 2em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #323232;
  color: #fff;
}
.button {
  outline: 0;
}
.button:focus,
.button:hover {
  background-color: #0c54a0;
  color: #fff;
}

@media (max-width: 991px) {
  .subscribe-mail-wrapper {
    display: block;
    margin: 10px auto;
  }
  .button {
    vertical-align: top;
    height: 45px;
  }
  .subscribe-mail-wrapper input {
    width: 50%;
  }
}

/* ###########################################################################
#####
#####
#####  INPUT SWITCH BOTÓN
#####
#####
###########################################################################*/

.switch {
  position: relative;
  display: inline-block;
  width: 250px;
  height: 54px;
  vertical-align: middle;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #73b33c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 46px;
  width: 46px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #cf1c00;
} /*parar*/
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(195px);
  -ms-transform: translateX(195px);
  transform: translateX(195px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute; /*transform: translate(-50%,-50%); top: 50%; left: 50%;*/
  top: 15px;
  left: 0px;
  text-align: center;
  font-size: 1.25em;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  width: 100%;
}

input:checked + .slider .on {
  display: block;
}
input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 6px;
}
.slider.round:before {
  border-radius: 50%;
}

/*Input opciones*/

.advierte {
  border: 1px solid #ffc000;
}
.correcto {
  border: 1px solid #00ff12;
}
.erroneo {
  border: 1px solid #ff0000;
}
