body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  /*font-size:0.8750em; /*14px*/
  /*font-size:0.9375em; /*15px*/
  font-size: 1em; /*16px*/
  line-height: 1.5714em;
  color: #777777;
  background: #ffffff;
  height: 100%;
  width: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

.searchform {
  position: relative;
  top: -1rem;
  transform: translateX(-50%, -50%);
  transition: all 1s;
  width: 50px;
  height: 50px;
  background: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;
  margin-right: 1rem;
}

.searchinput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.searchfa {
  box-sizing: border-box;
  padding: 10px;
  width: 42.5px;
  height: 42.5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #1da0f5;
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
}

.searchform:hover {
  width: 200px;
  cursor: pointer;
}

.searchform:hover input {
  display: block;
}

.searchform:hover .fa {
  background: #1da0f5;
  color: white;
}
