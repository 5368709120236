@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/*Colores identidad de la web FONDO*/
.destacado {
  background-color: #eab310;
}

/*Colores identidad de la web TEXTO*/
.c-destacado {
  color: #eab310;
}
.c-destacado1 {
  color: #41bbfe;
}

/*BotÃ³n hover*/
.boton:hover,
.boton:focus {
  color: #ffffff;
  background: #ddcc59;
}
@media (max-width: 991px) {
  .boton:hover,
  .boton:focus {
    color: #ffffff;
    background: #585858;
  }
} /*Comportamiento en movil*/

.boton.destacado {
  background-color: #eab310;
  color: #fff;
}
.boton.destacado:hover {
  background-color: #000000;
  color: #fff;
}

/*BotÃ³n hover en formulario*/
.button:focus,
.button:hover {
  background-color: #ff8b00;
  color: #fff;
}

/*Link invert*/
.ainvert:hover {
  color: #fff;
}

/*Fuente destacada*/
.fuente-destacada {
  font-family: 'Roboto Condensed', sans-serif;
}

/*selecciones*/
::selection {
  background: #eab310;
  color: #fff;
}
::-moz-selection {
  background: #eab310;
  color: #fff;
}

/*scrolltop*/
.scrolltop {
  background: #eab310 url(../images/top.png) no-repeat center center;
}

/*Separa de menu (espacio que ocupa el menu horizontalmente)*/
.separa-menu {
  height: 0px;
} /*Escritorio*/
@media (max-width: 991px) {
  .separa-menu {
    height: 0px;
  }
} /*Tableta / Movil*/

/* 
==============================
==   CONTENIDO DE LA WEB    ==
==============================
*/

/*CABECERA*/
.fon-cabecera {
  background: url(../images/fontop.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 481px;
}
.fon-cabecera:after {
  /*Hack IOS*/
  content: '';
  position: fixed; /* stretch a fixed position to the whole screen */
  top: 0;
  height: 100vh; /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  z-index: -1; /* needed to keep in the background */
  background: url(../images/fontop.svg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.box-slogan {
  text-align: center;
}

.slg-1 {
  font-size: 2.5em;
  font-weight: 300;
  line-height: 1em;
}
.slg-2 {
  font-size: 3.75em;
  font-weight: 700;
  line-height: 1em;
}

/*aminaciÃ³n del menÃº al hacer scroll ---------------------------------------------------------- */
/*menÃº top*/
.home_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.menu_fixed {
  position: fixed;
  z-index: 9999 !important;
  width: 100%;
  background-color: rgba(77, 192, 255, 0.9);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  top: 0;
}
.menu_fixed img {
  max-width: 300px;
}

/*animaciÃ³n aparece menÃº top*/
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print), (prefers-reduced-motion) {
  .animated {
    -webkit-transition: none !important;
    transition: none !important;
    /*-webkit-animation: unset !important; animation: unset !important;*/
  }
}
/* fin aminaciÃ³n del menÃº al hacer scroll ---------------------------------------------------------- */

.box-list1 {
  font-size: 1.375em;
  color: #4dc0ff;
  background-color: #dff3fd;
  font-weight: 700;
  line-height: 1em;
  padding: 25px 16px;
}
.box-list2 {
  font-size: 1.375em;
  color: #ffffff;
  background-color: #9adafe;
  font-weight: 700;
  line-height: 1em;
  padding: 25px 16px;
}
.box-list3 {
  font-size: 1.375em;
  color: #ffffff;
  background-color: #eab310;
  font-weight: 700;
  line-height: 1em;
  padding: 25px 16px;
}

.list-domains {
  border: 0;
  width: 100%;
}
.list-domains th,
.list-domains td {
  border: 0;
  background-color: rgba(255, 255, 255, 0);
}
.list-domains th {
  text-align: left;
}
.list-domains tr {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  height: 80px;
}
@media (max-width: 768px) {
  .list-domains tr {
    height: 50px;
  }
}

.list-domains tr td:last-child {
  text-align: right;
}

.txt-col1 {
  max-width: 0;
}
.txt-col2 {
  width: 5%;
}
.txt-col3 {
  width: 30%;
}
.txt-col1,
.txt-col2 {
  font-size: 1em;
}
@media (max-width: 768px) {
  .txt-col1,
  .txt-col2 {
    font-size: 0.8em;
  }
  .txt-col3 {
    width: 20%;
  }
}

.boton.buy {
  background-color: #eab310;
  border-radius: 30px;
  color: #000000;
}
.boton.buy:hover {
  background-color: #02be6e;
}
@media (max-width: 1400px) {
  .boton.buy {
    padding: 5px 10px;
    font-size: 0.8em;
    line-height: 1em;
  }
}

.box-list2frs {
  height: 80px;
  color: #696969;
  padding: 25px;
}
.box-categories {
  background-color: #ededed;
  padding: 22px;
}
.box-categories-item {
  padding: 20px 15px;
  display: block;
  color: #313131;
}
.box-categories-item:hover {
  background-color: #d4d4d4;
}
@media (max-width: 768px) {
  .box-categories-item {
    padding: 14px 15px;
  }
}

.cajita {
  width: 11px;
  height: 11px;
  display: inline-block;
  background-color: #eab310;
}

.boton.more {
  background-color: #ffffff;
  border-radius: 40px;
  color: #4dc0ff;
  border: 1px solid #e2e2e2;
}
.boton.more:hover {
  background-color: #eab310;
  color: #000;
}
.boton.more:focus {
  outline: none;
}

/*Zona formulario*/
.fon-formulario {
  background: url(../images/fon-form.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.fon-formulario:after {
  /*hack ios*/
  content: '';
  position: fixed; /* stretch a fixed position to the whole screen */
  top: 0;
  height: 100vh; /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  z-index: -1; /* needed to keep in the background */
  background: url(../images/fon-form.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.tit-form {
  font-size: 3.125em;
  line-height: 1em;
  font-weight: 700;
}
.subtit-form {
  font-size: 1.375em;
  line-height: 1em;
  font-weight: 300;
}
#formulario_inferior .formulario {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  margin-bottom: 20px;
}
#formulario_inferior input[type='button'].formulario,
input[type='submit'].formulario {
  background-color: #02be6e;
  color: #fff;
  width: auto;
  text-decoration: none;
  border: none;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-indent: 2px;
  padding: 14px 45px;
  margin-bottom: 0;
  -webkit-appearance: button;
  font-weight: 300;
  font-size: 1.4em;
  display: inline-block;
}
#formulario_inferior input {
  color: grey;
}
#formulario_inferior input:focus {
  color: black;
}

#formulario_inferior .boton.enviar:hover {
  background-color: #6b9b00;
}

#formulario_inferior .formulario::-moz-placeholder {
  color: #757575;
  opacity: 1;
}
#formulario_inferior .formulario:-ms-input-placeholder {
  color: #757575;
}
#formulario_inferior .formulario::-webkit-input-placeholder {
  color: #757575;
}

#formulario_inferior textarea {
  color: #797979;
}
#formulario_inferior textarea:focus {
  color: #000;
}

/*LANDING*/
.fon-landing {
  background: url(../images/fon-landing.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.fon-landing:after {
  /*hack ios*/
  content: '';
  position: fixed; /* stretch a fixed position to the whole screen */
  top: 0;
  height: 100vh; /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  z-index: -1; /* needed to keep in the background */
  background: url(../images/fon-landing.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.box-nombredominio {
  color: #e4ff00;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 7px 40px;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1em;
  border-radius: 30px;
  -webkit-box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.22);
}
@media (max-width: 991px) {
  .box-nombredominio {
    font-size: 1.5em;
  }
}

.bajo-dominio {
  font-size: 1.875em;
  line-height: 1em;
}
@media (max-width: 991px) {
  .bajo-dominio {
    font-size: 1.5em;
  }
}

.coltexto {
  justify-content: flex-end;
}
.box-boxizq {
  text-align: right;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.44);
}
@media (max-width: 991px) {
  .box-boxizq {
    text-align: center;
  }
}

.linea1 {
  font-size: 1.875em;
  line-height: 1em;
  margin-bottom: 10px;
  display: block;
}
.linea2 {
  font-size: 3.437em;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
@media (max-width: 991px) {
  .linea2 {
    font-size: 2.5em;
  }
}
.linea3 {
  font-size: 5em;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
}
@media (max-width: 991px) {
  .linea3 {
    font-size: 4em;
  }
}
.linea4 {
  font-size: 1.25em;
  line-height: 1.2em;
}
@media (max-width: 991px) {
  .linea4 {
    font-size: 1em;
  }
}

.box-formlanding {
  padding: 33px;
  background-color: rgba(0, 0, 0, 0.15);
  max-width: 380px;
}
@media (max-width: 991px) {
  .box-formlanding {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

#formulario_landing .formulario {
  border-radius: 0;
  padding: 10px 12px;
  margin-bottom: 10px;
}
#formulario_landing input[type='button'].formulario,
input[type='submit'].formulario {
  background-color: #00ff66;
  color: #000;
  padding: 15px;
  font-size: 1.375em;
  font-weight: 700;
}
.flecha {
  position: absolute;
  right: -53px;
  bottom: -37px;
  z-index: 1;
}

@media (max-width: 991px) {
  .centradovertical.landing {
    display: block;
  }
}

.box-nodomain {
  width: 100%;
  background-color: #18ff00;
  text-align: center;
  color: #000;
  padding: 10px;
  display: block;
}
.box-nodomain:hover {
  background-color: #ff6000;
}
.box-nodomain {
  color: #000;
}

.box-sombra {
  -webkit-box-shadow: 0px 4px 13px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 13px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 13px 10px rgba(0, 0, 0, 0.1);
}
a {
  text-decoration: none;
  color: #777777;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #000000;
} /*cambia color*/
footer,
header,
article,
section,
div,
h1,
h2,
h3,
h4,
p,
form,
input,
textarea,
img,
span,
td {
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
h1,
h2,
h3,
h4 {
  font: inherit;
}
img {
  border: none;
}
video {
  width: 100%;
  height: auto;
  border: 0;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

sup,
sub {
  font-size: 0.8125em;
}

/*=========================
  03. Generales
=========================*/

.limpiar {
  clear: both;
  font-size: 0;
  line-height: 0;
  float: none;
}

acronym,
abbr {
  border-bottom: 1px dotted #333;
  cursor: help;
  font-style: normal;
  text-decoration: none;
}

.separa {
  width: 100%;
  height: 30px;
  font-size: 0;
  clear: both;
}
.separa-med {
  width: 100%;
  height: 50px;
  font-size: 0;
  clear: both;
}
.separa-min {
  width: 100%;
  height: 20px;
  font-size: 0;
  clear: both;
}
.separa-peq {
  width: 100%;
  height: 10px;
  font-size: 0;
  clear: both;
}

.separa1 {
  width: 100%;
  height: 60px;
  font-size: 0;
  clear: both;
}
.separa2 {
  width: 100%;
  height: 70px;
  font-size: 0;
  clear: both;
}
.separa3 {
  width: 100%;
  height: 80px;
  font-size: 0;
  clear: both;
}

.izq {
  float: left;
}
.der {
  float: right;
}

.italic {
  font-style: italic;
}

.septop {
  margin-top: 50px;
}
.sepbot {
  margin-bottom: 40px;
}
.septopbot {
  margin: 25px 0;
}

.sepbotmin {
  margin-bottom: 20px;
}
.septopmin {
  margin-top: 10px;
}

.sepizq {
  margin-left: 10px;
}
.sepder {
  margin-right: 10px;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}

.justificado {
  text-align: justify;
}
.enlinea {
  display: inline-block;
}
.enbloque {
  display: block;
}

.centrado {
  margin: 0 auto;
  display: block;
  text-align: center;
  float: none;
}
.centable {
  margin: 0 auto;
  display: table;
}
.full {
  width: 100%;
}

.centradovertical {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} /*En flexbox*/
.verticalcenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.position-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.position-top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.position-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.position-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.tachado {
  text-decoration: line-through;
}

.underline {
  border-bottom: 1px solid transparent;
  border-bottom-color: initial;
  display: inline-block;
}
*::-ms-backdrop,
.underline {
  text-decoration: underline;
} /* IE11 */

.zoomin {
  cursor: zoom-in;
}

.noheigh {
  height: auto;
  overflow: inherit;
}
.nobold {
  font-weight: normal;
}
.nomargin {
  margin: 0;
}

.linea {
  display: block;
  margin: 50px 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);
}
.linea-med {
  display: block;
  margin: 30px auto;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);
}
.linea-min {
  display: block;
  margin: 20px auto;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);
}
.linea-abajo {
  display: block;
  margin: 50px auto;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.linea-min-abajo {
  display: block;
  margin: 10px auto;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
}

.redondo {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.redondo-min {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}
.redondo-big {
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.redondo-top {
  border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
}
.redondo-bottom {
  border-radius: 0px 0px 4px 4px;
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px 0px 4px 4px;
}

.redondo6 {
  border-radius: 6px;
}
.redondo8 {
  border-radius: 8px;
}
.redondo12 {
  border-radius: 12px;
}
.redondo16 {
  border-radius: 16px;
}
.redondo20 {
  border-radius: 20px;
}

/*.visible_en_movil, .visible{visibility:hidden; display:none;}*/

.mayusculas {
  text-transform: uppercase;
}

.capa1 {
  z-index: 2;
}

.post-imagen {
  float: left;
  margin-bottom: 0px;
}
.post-texto {
  display: block;
  overflow: hidden;
  padding: 0px 0px 2px 10px;
  margin-bottom: 0px;
}

.imgborde {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.help {
  cursor: help;
}

.sombra {
  -webkit-box-shadow: 0px 0px 28px -2px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 28px -2px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 28px -2px rgba(0, 0, 0, 0.28);
}
.sombra-abajo {
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
}
.sombra-texto {
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
}

.croptexto {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/*=========================
  04. GenÃ©ricas
=========================*/

/*Scroll aparece*/
.scrolltop {
  width: 45px;
  height: 45px;
  text-indent: -9999px; /*opacity:0.8;*/
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: none;
  background: #0c54a0 url('../images/top.png') no-repeat center center;
  z-index: 1000;
} /*cambia color*/

/*ResoluciÃ³n*/
#dimensions {
  position: fixed;
  left: 10px;
  bottom: 0px;
  background: #000\9;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  color: #fff;
  padding: 5px 10px;
  z-index: 1000;
}

/*selecciones*/
::selection {
  background: #0c54a0;
  color: #fff;
}
::-moz-selection {
  background: #0c54a0;
  color: #fff;
}

/*=========================
  05. PopUps / modals
=========================*/
/*&19a/b*/

/*POPUP MODAL "LOGIN"*/
.cont-modal0 {
  padding: 20px;
}
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
}
.popupt {
  margin: 50px auto;
  background: rgb(0, 0, 0, 0);
  position: relative;
  z-index: 12000;
  overflow: hidden;
}
.inerpopup {
  border-radius: 15px;
  background-color: #454545;
  border: 3px solid #fff;
  position: relative;
  width: 320px;
  margin-top: 45px;
}
.user {
  position: absolute;
  top: -45px;
  right: 113px;
  z-index: 100;
}
.inerpopup__login {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  padding: 20px 15px 10px 15px;
  background-color: #b4b4b4;
}
.inerpopup__cajaform {
  overflow: hidden;
  border-radius: 13px;
}
.inerpopup__form {
  padding: 15px;
  border-radius: 0;
}
.inerpopup_cerrar {
  position: absolute;
  top: 15px;
  right: 10px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}
/*Fin POPUP MODAL "LOGIN"*/

.fondo-trans {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  /*transition: opacity 500ms;*/
  /*visibility: hidden;*/
  /*opacity: 0;*/
  display: none;
  z-index: 11000;
  overflow-y: auto;
}

.fondo-trans:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 50px auto;
  background: #fff;
  position: relative;
  z-index: 12000;
  overflow: hidden;
}

.popup.filtros {
  width: 350px;
  background: #fff;
  border-top: 6px solid #d93d52;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.692em;
  display: inline;
}

.popup .cerrar {
  position: absolute;
  top: 6px;
  right: 10px;
  transition: all 200ms;
  font-size: 1.3em;
  font-weight: normal;
  text-decoration: none;
  color: #333;
  z-index: 2;
}
.popup .cerrar:hover {
  color: #000000;
}
.popup .contenido {
  /*margin-top:10px;*/ /*max-height: 100px; overflow: auto;*/
}

@media screen and (max-width: 700px) {
  .popup {
    width: 80%;
  }
}
/*@media screen and (max-width: 420px){.popup.login, .popup.filtros {width: 300px; margin:20px auto}}*/

.modal-full {
  max-width: 90%;
}
.modal-big {
  max-width: 1000px;
}
.modal-med {
  max-width: 600px;
}
.modal-min {
  max-width: 400px;
}

@media (max-width: 767px) {
  .modal-med {
    max-width: 90%;
  }
  .modal-min {
    max-width: 90%;
  }
  /*  .modal .modal-content {padding:20px 15px;}*/
}

/*_______________CONTENIDOS MODALES_______________*/

/*Modal0*/
.cont-modal0 {
  padding: 20px;
}
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
}

/*Modal1*/
.cont-modal1 {
  padding: 0px;
}
.head-contmodal,
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
}

/*Modal2*/
.cont-modal2 {
  padding: 0px;
}
.head-contmodal,
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}

/*Modal3*/
.cont-modal3 {
  padding: 0px;
}
.head-contmodal,
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}

/*Modal4*/
.cont-modal4 {
  padding: 0px;
}
.head-contmodal,
.cuerpo-contomodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}
.pie-modal {
  background-color: #cbcbcb;
}

/*Modales sin contenedor para usar con el generador de modales*/
.boxmodal {
  -webkit-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.1);
}
.boxmodal__cont {
  padding: 25px;
}
.boxmodal__cont--rayita {
  border-bottom: 1px solid #e7e7e7;
}
.boxmodal__head {
  border-bottom: 1px solid #e7e7e7;
  height: 25%;
  font-size: 1.5em;
}
.boxmodal__boxtitulo {
  padding: 20px 0px 20px 60px;
}
.boxmodal__titulo {
  font-size: 0.9em;
}
.boxmodal__subtitulo {
  font-size: 0.6em;
  line-height: 1.1em;
}
.boxmodal__titulo-ico {
  padding: 0 25px;
}
.boxmodal__titulo-ico span {
  font-size: 2em;
  line-height: 2em;
}
.boxmodal__slogan {
  font-size: 1.2em;
}
.boxmodal__icono {
  position: absolute;
  bottom: -34px;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
  font-size: 2em;
  border-radius: 50%;
}
.boxmodal__icono i {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
}
.boxmodal__parrafo {
  font-size: 0.9em;
  line-height: 1.4em;
}
.boxmodal__pie {
  font-size: 0.8em;
  line-height: 1.3em;
}
.boxmodal__footer {
  padding: 0 25px;
}
.boxmodal__footer--borde {
  border-top: 1px solid #e7e7e7;
  font-size: 1em;
}
.boxmodal__botones {
  padding: 10px;
}

/*===============================
  06. DiseÃ±o en caja
=================================*/
/* Se aplica al body */

.page-wrapper {
  background-color: #ffffff;
  width: 100%;
}

.boxed .page-wrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  -webkit-box-shadow: 1px -22px 54px 5px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 1px -22px 54px 5px rgba(0, 0, 0, 0.53);
  box-shadow: 1px -22px 54px 5px rgba(0, 0, 0, 0.53);
}

.fondoweb {
  background: #f1f1f1 url('../images/fontop.svg') repeat fixed; /*center center fixed */
  /*-webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;*/
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .boxed .page-wrapper {
    width: 750px;
  }
}

@media (max-width: 768px) {
  .fondoweb {
    background: none;
  }
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
  .boxed .page-wrapper {
    width: 970px;
  }
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) {
  .boxed .page-wrapper {
    width: 1170px;
  }
}

/* Large devices (Large desktops 1400px and up) */
@media (min-width: 1400px) {
  .boxed .page-wrapper {
    width: 1370px;
  }
}

/* Large devices (Sueperlarge desktops 1700px and up) 
@media (min-width:1700px) {.boxed .page-wrapper {width: 1670px;}}*/

/*=========================
  07. Header
=========================*/

.fontop {
  width: 100%;
  padding: 0;
  color: white;
  position: fixed;
  z-index: 100;
  border-bottom: 0 solid #dbdbdb;
}

.logotipo {
  max-width: 232px;
  margin: 89;
  line-height: 91px;
  vertical-align: middle;
}

/*@media (max-width:1200px) {  .logotipo { margin:0;} 
                            .logotipo img { width: 220px }}*/
@media (max-width: 991px) {
  .logotipo {
    margin: 5px 0;
    line-height: 3em;
  }
  .logotipo img {
    width: 180px;
    padding-top: 10px;
  }
}

.icos-menu {
  font-size: 2.3em;
  padding: 18px 0;
  visibility: hidden;
  display: none;
}
.icos-menu__link {
  color: #c0107d;
}
.icos-menu__link:link,
.icos-menu__link:visited,
.icos-menu__link:active,
.icos-menu__link:hover {
  color: #ffffff;
}

@media (max-width: 991px) {
  .icos-menu {
    display: inline-block;
    visibility: visible;
  }
}

.zona-menu {
  margin-top: 0px;
  float: right;
}

/*Menu desktop (eliminar si se mete el menu complejo) */
.menu-desktop {
  line-height: 1.1em;
  text-align: center;
  vertical-align: middle;
}
.menu-opcion {
  line-height: 3em;
  display: inline-block;
  margin: 20px 0;
  padding: 0px 10px;
}
.menu-opcion.resaltado {
  background-color: #fc9100;
}
.menu-opcion.resaltado:hover {
  background-color: #ffc926;
}
.menu-opcion.resaltado a {
  color: #fff;
}
.menu-opcion a {
  color: #ffffff;
  font-weight: 300;
}
.menu-opcion a:hover {
  color: #000;
}
.menu-opcion.login {
  font-size: 1em;
  color: #e39000;
}
.menu-opcion.login a {
  color: #e39000;
}
.menu-opcion:hover {
  background-color: none;
  border-bottom: 0px solid #fc9100;
  webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .menu-desktop {
    display: none;
  }
}

/*Menu movil*/
#menumovil {
  display: none;
}

.menu-movil {
  position: fixed;
  top: 63px;
  right: 0;
  z-index: 2;
  display: block;
  width: 100%;
  padding: 0 0px;
  box-shadow: -2px 0px 3px grey;
  border-bottom: 2px solid grey;
}
.menu-opcionm {
  display: block;
  background-color: #e4e4e4;
  border-bottom: 1px solid #fff;
}
.menu-opcionm a {
  font-size: 1.2em;
  width: 100%;
  line-height: 24px;
  padding: 20px 20px;
  display: block;
}
.menu-opcionm.resaltado {
  background-color: #e39000;
}
@media (min-width: 991px) {
  #menumovil {
    display: none;
    visibility: hidden;
  }
}

/*=========================
  08. Footer
=========================*/

/*Ajusta web al 100% alto*/
.ajustafooter {
  min-height: 100vh;
  position: relative;
}
.ajustapie {
  height: 278px;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

/*Zona legal*/
.zona-legal {
  font-size: 0.7em;
  background-color: #595959;
  padding: 25px 15px;
}
.zona-legal a {
  color: #878787;
}

.color-f-pie {
  background-color: #606060;
}
.zona-abajo {
  color: #fff;
  font-size: 0.9em;
}
.desresalta {
  color: #b9b9b9;
}

/*=========================
  09. Zonas y cajas
=========================*/

/* zona y fondos mÃ³dulos secciones (separaciones arriba y abajo) */
.zona {
  padding: 3em 0;
}
.zona.big {
  padding: 4em 0;
}
.zona.min {
  padding: 2em 0;
}
.zona.peq {
  padding: 1.375em 0;
}
.zona.notop {
  padding-top: 0em;
}
.zona.nobottom {
  padding-bottom: 0em;
}

/*masonry- necesita macy.min.js y script en custom.js*/
#macy-container::before {
  content: '';
  display: table;
  clear: both;
}
#macy-container::after {
  content: '';
  display: table;
  clear: both;
}

.cajamasonry {
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  background: #f2f2f2;
  padding-bottom: 10px;
}
.cajamasonry img {
  margin: 0 auto;
  text-align: center;
}
.cajamasonry .inforeview {
  padding: 10px 10px 0px 10px;
  font-size: 0.875em;
  line-height: 150%;
}
.cajamasonry .inforeview span {
  font-weight: bold;
  font-size: 1.125em;
  color: #002967;
  display: inline-block;
}
.cajamasonry .inforeview .verified {
  background: #5ab8ae;
  padding: 5px;
  color: white;
  font-size: 0.75em;
  line-height: 0.75em;
  cursor: help;
  display: inline-block;
  border-radius: 2px;
}
.cajamasonry .inforeview span i {
  font-weight: normal;
  margin-right: 3px;
}
.cajamasonry .inforeview .starszone {
  text-align: left;
  margin: 2px 0;
}
.cajamasonry .inforeview.bottom {
  padding-top: 0px;
}

@media (min-width: 2000px) {
  .cajamasonry img {
    display: block;
    max-width: 375px;
  }
}

/*=========================
  10. Links y botones
=========================*/

/*&03*/
/*LINKS Y BOTONES*/
.boton {
  background: #a2a2a2;
  color: #fff;
  font-size: 0.9375em;
  padding: 8px 25px;
  letter-spacing: 0.5px;
  margin: 0px;
  border: none; /*border-radius:0px;*/
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
}
.boton::after {
  /*content: "\203A";*/
  font-size: 1.1333em;
  margin-left: 5px;
} /*Pone una flecha a la derecha y se descomenta*/
.boton:hover,
.boton:focus {
  color: #ffffff;
  background: #f7921a;
}
.boton:hover span {
  color: #fff;
}

.boton a {
  color: white;
}

.boton.destacado {
  background-color: none;
}

.boton.info:hover span:last-child {
  color: #fff;
  margin-left: 5px;
}
.boton.info:hover span {
  color: #fff;
}

.boton.invert,
.boton.invert:focus {
  color: #ffffff;
  background: #f7921a;
  border: 0px solid #f7921a;
}
.boton.invert:hover {
  color: #fff;
  background: #2a2a2a;
}

.boton.full {
  width: 100%;
  display: block;
}
.boton.big {
  padding: 12px 35px;
  font-size: 1.0625em;
}
.boton.min {
  padding: 7px 25px 6px 25px;
}

/*colores*/
.boton.verde,
.boton.verde:focus {
  color: #fff;
  background: #00c05f;
}
.boton.verde:hover {
  color: #ffffff;
  background: #000;
}
.boton.verde a {
  color: white;
}

.boton.rojo,
.boton.rojo:focus {
  color: #fff;
  background: #a11e1e;
}
.boton.rojo:hover {
  color: #ffffff;
  background: #000;
}
.boton.rojo a {
  color: white;
}

.boton.naranja,
.boton.naranja:focus {
  color: #fff;
  background: #ff8a00;
}
.boton.naranja:hover {
  color: #ffffff;
  background: #000;
}
.boton.naranja a {
  color: white;
}

.boton.azul,
.boton.azul:focus {
  color: #fff;
  background: #2d6cba;
}
.boton.azul:hover {
  color: #ffffff;
  background: #000;
}
.boton.azul a {
  color: white;
}

.boton.amarillo,
.boton.amarillo:focus {
  color: #fff;
  background: #ffcc00;
}
.boton.amarillo:hover {
  color: #ffffff;
  background: #000;
}
.boton.amarillo a {
  color: white;
}

.boton.negro,
.boton.negro:focus {
  color: #fff;
  background: #090909;
}
.boton.negro:hover {
  color: #ffffff;
  background: #414141;
}
.boton.negro a {
  color: white;
}

.boton.gris,
.boton.gris:focus {
  color: #fff;
  background: #868686;
}
.boton.gris:hover {
  color: #ffffff;
  background: #000;
}
.boton.gris a {
  color: white;
}

.boton.blanco,
.boton.blanco:focus {
  color: #868686;
  background: #fcfcfc;
}
.boton.blanco:hover {
  color: #ffffff;
  background: #000;
}
.boton.blanco a {
  color: #868686;
}

.boton.cancela {
  background-color: #e3e3e3;
  color: grey;
}
.boton.cancela:hover {
  color: grey;
  background-color: #c3c3c3;
}

/*.boton.whatsapp{background:#455a64 url("../images/whatsapp-logo.png") left 12px top 9px no-repeat; padding:8px 10px 8px 36px;}
.boton.whatsapp:hover, .boton.whatsapp:focus{background:#5b6662 url("../images/whatsapp-logo.png") left 12px top 9px no-repeat;}
*/
.boton.enviar {
  font-weight: bold;
  font-size: 1em;
}
.boton.enviar:hover {
  background-color: #6b9b00;
}

.boton.cancelar {
  font-size: 0.8em;
  background-color: #e84e40;
  padding: 2px 8px;
}
.boton.cancelar::after {
  content: none;
}
.boton.cancelar:hover {
  background-color: #ea3a3a;
}
@media (max-width: 991px) {
  .boton.cancelar:hover {
    background-color: #c00000;
  }
} /*Comportamiento en movil*/

.boton.cancelar.unsuscrito {
  background-color: #787878;
}

.boton.aceptar {
  font-size: 0.8em;
  background-color: #008000;
  padding: 2px 8px;
}
.boton.aceptar::after {
  content: none;
}
.boton.aceptar:hover {
  background-color: #5ead5e;
}
@media (max-width: 991px) {
  .boton.aceptar:hover {
    background-color: #008000;
  }
} /*Comportamiento en movil*/

.boton.img {
  background: #ccc;
  padding: 4px 9px;
  border-radius: 4px;
  color: #777777;
}

.boton.cerrar {
  padding: 6px;
  margin: 0;
  border-radius: 50%;
  line-height: 1em;
  font-size: 0.7em;
}

/*btn texto*/
a.btn-texto {
  color: #0c54a0;
  display: inline-block;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-decoration: none;
  outline: none;
  margin-top: 10px;
}
.btn-texto:after {
  content: '\203A';
  font-size: 1.1333em;
  margin-left: 5px;
}

/*Subraya animado*/
a.subrayar {
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s;
}
a.subrayar:hover,
a.subrayar:focus {
  background-size: 100% 1px;
}

/*EFECTOS BOTONES*/

/*crecer*/
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*empequeÃ±ecer*/
.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-vertical:hover,
.hvr-wobble-vertical:focus,
.hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-horizontal:hover,
.hvr-wobble-horizontal:focus,
.hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/*Efecto cambia color suave link*/

.grad-link {
  color: #000;
  -webkit-transition: color 1s ease-in; /*safari and chrome */
  -moz-transition: color 1s ease-in; /* firefox */
  -o-transition: color 1s ease-in; /* opera */
  transition: color 1s ease-in;
}
.grad-link:hover {
  color: #0c54a0;
}

.multiple a {
  color: #000;
  background: #ccc;
  padding: 5px;
  -webkit-transition-property: color, background;
  -webkit-transition-duration: 1s, 1s;
  -webkit-transition-timing-function: linear, ease-in;
}
.multiple a:hover {
  color: #fff;
  background: #c00;
}

/*=========================
  11. Listas 
=========================*/

/*&04*/
/*lista abajo*/
.lnkaba {
  margin: 0px 5px;
}
.lnkaba li {
  display: block;
  color: #777777;
  line-height: 1.8em;
  padding: 3px 0 0 15px;
  margin: 3px 0px;
}
.lnkaba li a {
  color: #777777;
  text-decoration: none;
}
.lnkaba li a:hover,
.lnkaba li a:focus {
  tex-decoration: none;
  color: #222;
}

/*lista simple*/
ul.simplelist {
  margin: 20px 0px;
  display: block;
}
ul.simplelist.nomargin {
  margin: 0px;
}
ul.simplelist li {
  margin: 0 20px 0 30px;
  padding: 7px 0 7px 0px;
}
ul.simplelist li.enlinea {
  display: inline-block;
  text-align: center;
  background: #ededed;
  padding: 10px;
  margin: 5px 0;
}
ul.simplelist li a {
  text-decoration: underline;
}

ul.simplelist.nopadding li {
  padding: 0px;
}

ul.simplelist li.num {
  list-style-type: decimal;
} /*nÃºmeros*/
ul.simplelist li.nu0 {
  list-style-type: decimal-leading-zero;
} /*nÃºmeros con 0 antes*/
ul.simplelist li.min {
  list-style-type: lower-latin;
} /*minÃºsculas*/
ul.simplelist li.may {
  list-style-type: upper-latin;
} /*mayÃºsculas*/
ul.simplelist li.cua {
  list-style-type: square;
} /*cuadrado*/
ul.simplelist li.pun {
  list-style-type: disc;
} /*cÃ­rculo relleno*/
ul.simplelist li.cir {
  list-style-type: circle;
} /*cÃ­rculo vacio*/

ul.simplelist li.check {
  margin: 10px 15px;
}
ul.simplelist li.check {
  background: url(../images/check.png) no-repeat 0px 7px;
  padding: 0px 0 0px 20px;
}

ul.simplelist li.descarga {
  margin: 10px 15px;
}
ul.simplelist li.descarga {
  background: url(../images/icono-descarga.png) no-repeat 0px 6px;
  padding: 0px 0 0px 20px;
}

ul.simplelist li.enlace {
  margin: 10px 15px;
}
ul.simplelist li.enlace {
  background: url(../images/icono-enlace.png) no-repeat 0px 6px;
  padding: 0px 0 0px 22px;
}

/*=========================
  12. Forms
=========================*/

/*.formulario{border-left: 0; border-top:0; border-right: 0; }*/
.formulario {
  border-radius: 4px;
}
/*.labelcampos { color: #8f8f8f; font-family: 'Fjalla One', sans-serif }*/
.ojo-pass {
  position: relative;
  z-index: 10;
  top: 35px;
  right: 12px;
  cursor: pointer;
  font-size: 1.5em;
  float: right;
}

.ico-campo {
  position: relative;
  z-index: 10;
  top: 35px;
  right: 12px;
  cursor: pointer;
  font-size: 1.5em;
  float: right;
}

/*buscador simple*/
.input-simple {
  margin-bottom: 20px;
}

a .btnformder {
  line-height: 195%;
}
.btnformder {
  display: table-cell;
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 2;
  background: #f6f6f6;
  height: 47px;
  width: 51px;
  font-size: 1.5714em;
  border-left: 0px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  border-radius: inherit;
  text-align: center;
}

a .btnformizq {
  line-height: 195%;
}
.btnformizq {
  display: table-cell;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 2;
  background: #f6f6f6;
  height: 47px;
  width: 51px;
  font-size: 1.5714em;
  border-right: 0px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  border-radius: inherit;
  text-align: center;
}

/*Buscador escritorio/movil (el del menÃº) */
.buscador {
  font: normal 1em Arial, Helvetica, sans-serif;
  color: #666;
  background: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px 10px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 20px;
  width: 150px;
  z-index: 200000;
}
.icobuscador {
  position: absolute;
  top: 10px;
  right: 15px;
  display: inline-block;
  font-size: 1.3em;
  vertical-align: middle;
  border-radius: inherit;
  text-align: center;
}
.icobuscador:hover {
  color: #777777;
}

@media (max-width: 991px) {
  .buscadormovil {
    display: none;
    position: fixed;
  }
  .buscador {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 57px;
  }
  .icocerrar {
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 200000;
  }
}

/*=========================
  13. PaginaciÃ³n
=========================*/

/*siguiente anterior*/
/*&08*/
.pager {
  padding-left: 0; /*margin-bottom:10px;*/
  text-align: center;
  list-style: none;
}
.pager li {
  display: inline;
  font-family: Arial, Helvetica, sans-serif;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: inherit;
  background-repeat: repeat-x;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #fff 0%, #f4f4f4 100%);
}
.pager li > a:focus,
.pager li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.pager .siguiente > a,
.pager .siguiente > span {
  float: right;
}
.pager .anterior > a,
.pager .anterior > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: #ddd;
  cursor: not-allowed;
  background-color: #fff;
}

/*paginaciÃ³n*/
/*&07*/
.paginacion li {
  display: inline;
  font-weight: normal;
  border-radius: inherit;
  line-height: 45px;
  border-radius: 0px;
}

.paginacion li a {
  color: #4b4b4b;
  background: #e1e1e1;
  text-decoration: none;
  padding: 10px 16px;
  margin: 5px 6px 0 0;
}
.paginacion li a:hover {
  background: #2a2a2a;
  color: #fff;
}
.paginacion li.disabled {
  padding: 10px 16px;
  margin: 5px 6px 0 0;
  background: #f5f5f5;
  color: #999;
  cursor: default;
}
.paginacion li.active {
  padding: 10px 16px;
  margin: 5px 6px 0 0; /*background: #f7921a;*/
  color: #fff;
  cursor: default;
} /*cambiar color*/

/*=========================
    14.Animaciones
=========================*/

/*ahover*/
.ahover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*animaciÃ³n underline izq / der*/
.animacion-underline {
  width: auto;
  display: inline;
}
.animacion-underline a {
  color: currentcolor;
  position: relative;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.animacion-underline a::before {
  left: 0;
  bottom: 0;
  content: '';
  right: 100%;
  height: 1px;
  position: absolute;
  background-color: white;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.animacion-underline a:hover::before {
  right: 0;
}

/*animaciÃ³n underline center*/
.animacion-underline-center {
  position: relative;
  display: inline-block;
}
.animacion-underline-center a,
.animacion-underline-center a:hover {
  color: white;
}
.animacion-underline-center:after {
  width: 0;
  height: 1px;
  content: '';
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -0px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.animacion-underline-center:hover:after {
  width: 100%;
}

/*Agranda un contenedor al pasar encima*/
.agranda {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.agranda:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}
.agranda.blur:hover {
  opacity: 0.8;
}

/*Agranda foto al entrar en la pÃ¡gina*/
.zoomentra {
  animation-duration: 10s;
  animation-name: zoomin;
}
@keyframes zoomin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
.zoomentra.alternate {
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

/*Encoje un contenedor al pasar encima*/
.encoje {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.encoje:hover {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.encoje.blur:hover {
  opacity: 0.8;
}

/*Resplandor*/
.glow {
  box-shadow: 0px 0px 5px 4px #3ca9fd;
}

/*Filtro color*/
.filtro-gris:hover {
  background: yellow;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
/*utiliza el color del background de la caja contenedora*/
.filtro-color:hover {
  mix-blend-mode: screen;
  -webkit-filter: grayscale(100%) contrast(200%);
  filter: grayscale(100%) contrast(200%);
  opacity: 1;
}

/*typewriter*/
/*&22*/
.typewriter h1 {
  color: #000;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/*Parpadeo y escalado*/
.blink05 {
  animation: blink 0.5s linear infinite;
}
.blink1 {
  animation: blink 1s linear infinite;
}
.blink2 {
  animation: blink 2s linear infinite;
}
.blink3 {
  animation: blink 3s linear infinite;
}
.blink4 {
  animation: blink 4s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.blk-spand02 {
  animation: blk-spand 0.2s linear infinite;
}
.blk-spand05 {
  animation: blk-spand 0.5s linear infinite;
}
.blk-spand1 {
  animation: blk-spand 1s linear infinite;
}

@keyframes blk-spand {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.02);
  }
  100% {
    transform: scaleX(1);
  }
}

/*=========================
  15. Slider
=========================*/

.img100x100 {
  position: relative;
  z-index: 1;
}

.slidercontent {
  display: block !important;
  color: white;
}
.innerslider {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: auto;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.slidercontent {
  max-width: 700px;
  margin-left: 8%;
}

.slider-text {
  font-size: 3em;
  font-family: 'Martel Sans', sans-serif;
  color: #fff;
  letter-spacing: 0px;
  line-height: 110%;
  padding: 0;
  margin: auto;
  text-transform: uppercase;
  display: inline;
  font-weight: 700;
}
.slider-text span {
  color: #f7921a;
}

.slider-text.movil {
  font-size: 2em;
}

@media (max-width: 991px) {
  .innerslider {
    justify-content: center;
  }
  .slidercontent {
    margin-left: 0px;
    padding: 20px;
  }
  .slider-text span {
    background: none;
    padding: 0px;
    color: #f7921a;
    font-weight: 800;
    display: inline;
  }
}

@media (min-width: 1400px) {
  .slidercontent {
    margin-left: 10%;
  }
}

@media (min-width: 1600px) {
  .slidercontent {
    margin-left: 15%;
  }
}

/*=========================
  16. Multimedia
=========================*/

/*google maps*/
.gmap {
  position: relative;
}
.gmap iframe {
  width: 100%;
  margin-bottom: 0px;
  border: 1px solid #e7e7e7;
}
#mapacontacto {
  width: 100%;
  height: 450px;
  border-bottom: 2px solid #fff;
}
#mapatrabajos {
  width: 100%;
  height: 500px;
  border: 1px solid #e7e7e7;
}

/*responsive video*/
@media (min-width: 1400px) {
  .zona_video {
    margin: 0 auto;
    width: 70%;
    display: table;
    background: blue;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /*padding-top: 30px;*/
  height: 0;
  overflow: hidden;
  margin: 0px auto;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* vÃ­deo ajustado al centro con un tamaÃ±o mÃ¡ximo */
.video-wrapper {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
}
.video-wrapper2 {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}

/*=========================
  17. Tablas
=========================*/
/*&05*/

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

table.conborde {
  border: 1px solid #ccc;
  border-radius: inherit;
}

.table td.img {
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px 5px;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
  border-top: 1px solid #ccc;
  min-width: 20px;
}

.table td.center,
th.center {
  text-align: center;
}

.table .odd {
  background: #e8edff;
}

.table th {
  font-weight: bold;
  background: #f0f0f0;
  color: #000;
  border-bottom: 1px solid #cdcdcd;
}
.table tfoot {
  background: #f0f0f0;
  color: #000;
  border-top: 1px solid #cdcdcd;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 0px solid #ddd;
}

.table .table {
  background-color: #fff;
}

/*Tabla simple*/
table.simpletabla {
  border-collapse: collapse;
  font-size: 0.8em;
}
table,
th,
td {
  border: 1px solid grey;
  padding: 7px;
}
th {
  color: #515151;
  background-color: #d9d9d9;
}

/*Tabla redondeada*/
table.tablaredonda {
  border-collapse: separate;
  border-spacing: 2px;
}
table.tablaredonda,
th.tablaredonda,
td.tablaredonda {
  border: 0px solid grey;
  padding: 7px;
  border: 0px;
}
.tablaredonda td {
  border: solid 0px;
  border: 0;
}
.tablaredonda th {
  font-size: 1em;
} /*TamaÃ±o fuente cabecera*/
.tablaredonda td {
  font-size: 0.9em;
} /*TamaÃ±o fuente celdas*/

/*PARA LA PRIMERA FILA TIENES DOS OPCIONES*/
/*AsÃ­ serÃ­a la cosa si has empezado con un tr */
.tablaredonda tr:first-child .tablaredonda td:first-child {
  border-top-left-radius: 10px;
}
.tablaredonda tr:first-child .tablaredonda td:last-child {
  border-top-right-radius: 10px;
}
.tablaredonda tr:first-child .tablaredonda td:only-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/*si en lugar de eso has usado la etiquetas thead y th es mÃ¡s sencillo todavÃ­a*/
.tablaredonda th:first-child {
  border-top-left-radius: 10px;
}
.tablaredonda th:last-child {
  border-top-right-radius: 10px;
}
.tablaredonda th:only-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/*Y ASÃ PONEMOS EL PIE*/
.tablaredonda tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.tablaredonda tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.tablaredonda tr:last-child td:only-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*Tabla responsiva*/
.tablarespon {
  border: 0px;
  border-collapse: collapse;
}
.tablarespon table,
.tablarespon th,
.tablarespon td {
  border: 1px solid grey;
  padding: 7px;
} /*Color del los bordes*/
.tablarespon thead th {
  font-weight: normal;
  font-size: 1.1em;
}
.tablarespon td {
  padding: 7px 10px;
  font-size: 1em;
}

th.tablarespon {
  padding: 7px;
}
.tablarespon th:first-child {
  text-align: left;
}

.tablarespon td:nth-child(1) {
  width: 70%;
}
.tablarespon td:nth-child(2) {
  width: 10%;
  text-align: right;
}
.tablarespon td:nth-child(3) {
  width: 10%;
  text-align: right;
}
.tablarespon td:nth-child(4) {
  width: 10%;
  text-align: right;
}

@media (max-width: 1200px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .tablarespon {
    margin-bottom: 0;
  }
  .table-responsive > .tablarespon > thead > tr > th,
  .table-responsive > .tablarespon > tbody > tr > th,
  .table-responsive > .tablarespon > tfoot > tr > th,
  .table-responsive > .tablarespon > thead > tr > td,
  .table-responsive > .tablarespon > tbody > tr > td,
  .table-responsive > .tablarespon > tfoot > tr > td {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 0px solid #ddd;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .tablarespon {
    margin-bottom: 0;
  }
  .table-responsive > .tablarespon > thead > tr > th,
  .table-responsive > .tablarespon > tbody > tr > th,
  .table-responsive > .tablarespon > tfoot > tr > th,
  .table-responsive > .tablarespon > thead > tr > td,
  .table-responsive > .tablarespon > tbody > tr > td,
  .table-responsive > .tablarespon > tfoot > tr > td {
    white-space: nowrap;
  }
}

/*=========================
  18. Redes sociales
      e iconos svg
=========================*/
/*&10*/

ul.social-icons {
  margin-top: 0px;
}
.social-icons li {
  vertical-align: top;
  display: inline-block;
  height: auto;
  margin-bottom: 4px;
}
.social-icons li img {
  width: 18px;
  height: auto;
}

.facebook,
.twitter,
.youtube,
.linkedin,
.google-plus,
.vimeo,
.whatsapp,
.pinterest,
.instagram,
.flickr,
.rss,
.paypal,
.telegram {
  display: block;
  cursor: pointer;
  color: grey;
}
.facebook span,
.twitter span,
.youtube span,
.linkedin span,
.vimeo span,
.whatsapp span,
.pinterest span,
.instagram span,
.flickr span,
.rss span,
.paypal span,
.telegram span {
  font-size: 1.3em;
}
.facebook span:hover,
.twitter:hover span,
.youtube span:hover,
.linkedin span:hover,
.vimeo span:hover,
.whatsapp span:hover,
.pinterest span:hover,
.instagram span:hover,
.flickr span:hover,
.rss span:hover,
.paypal span:hover,
.telegram span:hover {
  color: white;
}
.facebook {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.facebook:hover {
  background-color: #3d5b99;
  color: white;
}
.twitter {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.twitter:hover {
  background-color: #00aced;
  color: white;
}
.youtube {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.youtube:hover {
  background-color: #e64a41;
  color: white;
}
.linkedin {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.linkedin:hover {
  background-color: #0073a4;
  color: white;
}
.google-plus {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.google-plus:hover {
  background-color: #e25714;
  color: white;
}
.vimeo {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.vimeo:hover {
  background-color: #1ab7ea;
  color: white;
}
.whatsapp {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.whatsapp:hover {
  background-color: #3db629;
  color: white;
}
.pinterest {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.pinterest:hover {
  background-color: #bd081c;
  color: white;
}
.instagram {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.instagram:hover {
  background-color: #065b9e;
  color: white;
}
.flickr {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.flickr:hover {
  background-color: #0063db;
  color: white;
}
.rss {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.rss:hover {
  background-color: #eb8231;
  color: white;
}
.paypal {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.paypal:hover {
  background-color: #0b3281;
  color: white;
}
.telegram {
  padding: 8px 12px;
  background-color: #2a2a2a;
}
.telegram:hover {
  background-color: #00aced;
  color: white;
}

/*=========================
  19. Media Queries
=========================*/

.noverflow {
  overflow: visible;
}

.tablet.visible,
.movil.visible {
  visibility: hidden;
  display: none;
}
.visible-max {
  visibility: hidden;
  display: none;
}

@media (min-width: 1940px) {
}

@media (min-width: 1400px) {
  .visible-max {
    visibility: visible;
    display: inherit;
  }
}

@media (max-width: 1200px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; /*border:1px solid #ddd*/
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
}

@media (max-width: 991px) {
  .zona-bus-pes {
    margin: 40px auto 0 auto;
    display: table;
    float: none;
  }
  .zona-buscador button.icon {
    margin-left: -54px;
  }

  .zona-abajo {
    float: none;
    margin: 0 auto;
    text-align: center;
    max-width: 400px;
  }
  .lnkaba li {
    background: none;
    padding: 0px;
  }

  .centradomovil {
    margin: 0 auto;
    display: block;
    text-align: center;
    float: none;
  }

  /*.invisible{visibility:hidden; display:none;}
  .visible{visibility:visible; display:inline-block;}*/

  .tablet.invisible {
    visibility: hidden;
    display: none;
  }
  .tablet.visible {
    visibility: visible;
    display: inherit;
  }

  .septopc {
    margin-top: 0px;
  }
  /*.logotipo {text-align:center; margin:10px auto 10px auto; float:none;}*/

  /*.zona-centrada{margin:0 auto; display:table;}*/
  .elipsis.nohidden {
    white-space: normal;
    overflow: inherit;
    text-overflow: clip;
  }

  .tablet.noelipsis {
    white-space: normal;
    overflow: inherit;
    text-overflow: clip;
  }

  .centrado-tablet {
    margin: 0 auot;
    text-align: center;
    float: none;
  }
}

@media (max-width: 767px) {
  .zona-buscador {
    width: 320px;
  }
  .zona-buscador input#search {
    width: 320px;
  }
  .centrado-galeria {
    text-align: center;
    margin: 0 auto;
  }

  .invisible_en_movil {
    visibility: hidden;
    display: none;
  }
  .visible_en_movil {
    visibility: visible;
    display: inline-block;
  }

  .movil.invisible {
    visibility: hidden;
    display: none;
  }
  .movil.visible {
    visibility: visible;
    display: inherit;
  }

  .wrapper {
    display: block;
  }
  .gmap iframe {
    width: 100%;
    border: 0px;
    padding: 0;
  }
  .elipsis.nohiddenmin {
    white-space: normal;
    overflow: inherit;
    text-overflow: clip;
  }

  .movil.noelipsis {
    white-space: normal;
    overflow: inherit;
    text-overflow: clip;
  }

  .centrado-movil {
    margin: 0 auot;
    text-align: center;
    float: none;
  }
}

@media (min-width: 320px) and (max-width: 720px) {
  .cajablanca.max {
    padding: 40px 15px;
  }

  .img-izq,
  .img-der {
    float: none;
    text-align: center;
    width: auto;
    max-width: 502px;
    margin: 0 auto 15px auto;
  }
  .zonagaleria {
    text-align: center;
  }
}

@media (max-width: 720px) {
  .imgmin {
    width: 120px;
  }
}

/*=========================
  20. Mensajes Admin
=========================*/
/*&06*/

.msg-form {
  position: relative;
  display: inline-block;
  margin: 0px 0px 15px 0px;
  padding: 10px;
  font: normal 1em /*'Poppins',*/ Arial, Helvetica, sans-serif;
  /*min-width:180px;*/
  line-height: 130%;
}

.msg-form.min {
  padding: 8px;
  font-size: 0.9285em;
}

.msg-form.peq {
  font-size: 0.8571em; /*font-style:italic;*/
  padding: 4px;
  margin: 0px;
}

.msg-form.transparente {
  background: rgba(255, 255, 255, 0.65);
  border: 1px solid #ccc;
  color: #4b4b4b;
}

.msg-form.blanco {
  background: #fff;
  border: 1px solid #dbdbdb;
  color: #4b4b4b;
}
.msg-form.verde {
  background: #dff0d8;
  border: 1px solid #d6e9c6;
  color: #3c763d;
}
.msg-form.amarillo {
  background: #f9edcf;
  border: 1px solid #f6e4b8;
  color: #5f5952;
}
.msg-form.rojo {
  background: #f5e8eb;
  border: 1px solid #e4d1d6;
  color: #c33939;
}
.msg-form.amarilla {
  background: #ffffec;
  border: 1px solid #ececdb;
  color: #4b4b4b;
}
.msg-form.azul {
  background: #e4f0f1;
  border: 1px solid #c3dbde;
  color: #416888;
}

/*.posicion-mensajes{position: fixed;  right: 15px; top: 20px; z-index:5000;width:50%;}*/

.posicion-mensajes {
  position: fixed;
  z-index: 1000000;
  top: 15px;
  right: 20px; /*max-width:500px;*/
  width: 50%;
  right: calc(
    50% - 700px + 20px
  ); /*la mitad del tamaÃ±o mÃ¡ximo de pantalla definido en flexible*/
}

@media all and (max-width: 1400px) {
  .posicion-mensajes {
    right: 20px;
  }
}
@media all and (max-width: 1200px) {
  .posicion-mensajes {
    right: 20px;
  }
}
@media (max-width: 480px) {
  .posicion-mensajes {
    right: 10px;
    max-width: 400px;
    font-weight: normal;
  }
}
@media (max-width: 768px) {
  .posicion-mensajes {
    width: 90%;
  }
}

/*mensaje ok*/
.mensaje {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: url('../images/mensaje_correcto.svg') left 7px top 16px no-repeat;
  background-color: #5ebd5e;
  border: 1px solid #43a543;
  padding: 11px 15px 10px 36px;
  margin: 3px;
  display: block;
  font-size: 1.077em;
  /*min-width:200px;
  max-width:500px;*/
}

.mensaje a {
  color: #fff;
}
.mensaje a:hover {
  color: #fff;
}

/*aviso*/
.aviso {
  color: #333;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.3);
  background: url('../images/mensaje_aviso.svg') left 7px top 15px no-repeat;
  background-color: #ffae10 /*f4b04f*/;
  border: 1px solid #f19a1f;
  padding: 11px 15px 10px 36px;
  margin: 3px;
  font-size: 1.077em;
}

.aviso a {
  color: #333;
}
.aviso a:hover {
  color: #000;
}

/*error ko*/
.error {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: url('../images/mensaje_error.svg') left 7px top 14px no-repeat;
  background-color: #e66454;
  border: 1px solid #df3c28;
  padding: 11px 15px 10px 36px;
  margin: 3px;
  font-size: 1.077em;
}

.error a {
  color: #fff;
}
.error a:hover {
  color: #fff;
}

.btn-cerrar {
  text-align: center;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
}
.btn-cerrar a {
  display: block;
  font: bold 1em Arial, Helvetica, sans-serif;
  color: #000;
  padding: 4px 8px;
  text-decoration: none;
  background: #fff\9;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: inherit;
  text-shadow: none;
}
.btn-cerrar a:hover {
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.8);
}

/*___________TOOL TIPS_______________*/
/*&02*/

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.9em;
}
.tooltip .tiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltip:hover .tiptext {
  visibility: visible;
}

/*Arriba*/

.tooltip.top .tiptext {
  margin-left: -60px;
  bottom: 150%;
  left: 50%;
}
.tooltip.top .tiptext::after {
  margin-left: -5px;
  top: 100%;
  left: 50%;
  border-color: #000 transparent transparent transparent;
}

/*Abajo*/

.tooltip.bottom .tiptext {
  margin-left: -60px;
  top: 150%;
  left: 50%;
}
.tooltip.bottom .tiptext::after {
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #000 transparent;
}

/*Izquierda*/

.tooltip.left .tiptext {
  top: -5px;
  right: 110%;
}
.tooltip.left .tiptext::after {
  margin-top: -5px;
  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent #000;
}

/*Derecha*/
.tooltip.right .tiptext {
  top: -5px;
  left: 110%;
}
.tooltip.right .tiptext::after {
  margin-top: -5px;
  top: 50%;
  right: 100%;
  border-color: transparent #000 transparent transparent;
}

/*Colores tooltip*/
.tooltip .tiptext.rojo {
  background-color: red;
}
.tooltip.top .tiptext.rojo::after {
  border-color: #ff0000 transparent transparent transparent;
}
.tooltip.bottom .tiptext.rojo::after {
  border-color: transparent transparent #ff0000 transparent;
}
.tooltip.left .tiptext.rojo::after {
  border-color: transparent transparent transparent #ff0000;
}
.tooltip.right .tiptext.rojo::after {
  border-color: transparent #ff0000 transparent transparent;
}
.tooltip .tiptext.verde {
  background-color: #00e516;
}
.tooltip.top .tiptext.verde::after {
  border-color: #00e516 transparent transparent transparent;
}
.tooltip.bottom .tiptext.verde::after {
  border-color: transparent transparent #00e516 transparent;
}
.tooltip.left .tiptext.verde::after {
  border-color: transparent transparent transparent #00e516;
}
.tooltip.right .tiptext.verde::after {
  border-color: transparent #00e516 transparent transparent;
}
.tooltip .tiptext.gris {
  background-color: grey;
}
.tooltip.top .tiptext.gris::after {
  border-color: grey transparent transparent transparent;
}
.tooltip.bottom .tiptext.gris::after {
  border-color: transparent transparent grey transparent;
}
.tooltip.left .tiptext.gris::after {
  border-color: transparent transparent transparent grey;
}
.tooltip.right .tiptext.gris::after {
  border-color: transparent grey transparent transparent;
}

/*=========================
  21. Componentes
=========================*/

/*____________TAB_MENU____________*/
/*&09*/

#tab {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #d5d5d5;
  padding: 6px 3px 6px 3px;
  min-width: 150px;
}

.tab {
  display: inline;
}
.tab ul {
  list-style: none;
  border-bottom: 1px solid #d6d6d6;
  margin: 0px;
}
.tab li {
  display: inline-block;
  border-radius: inherit;
  margin-right: 5px;
}
.tab a {
  display: inline-block;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-top: 4px solid #d6d6d6;
  border-bottom: 0px;
  color: #999;
  padding: 8px 15px;
  text-decoration: none;
  border-radius: inherit;
}
.tab a:hover {
  /*color: #fff; background:#333;*/
  color: #000;
  border-top: 4px solid #1f2b38;
}
.tab a.active {
  background: white;
  border: 1px solid #d6d6d6;
  border-top: 4px solid #1f2b38;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
  color: #333;
  border-radius: inherit;
}
.tab select {
  display: none;
}

@media (max-width: 990px) {
  .txttab {
    display: none;
  }
}

@media (max-width: 768px) /*990*/ {
  /*.tab ul { display: none; }*/
  .tab select {
    display: block;
    margin: 0 auto;
  }
}

/*____________COLAPSE Y  ACORDEON____________*/
/*&23*/
.collapse-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
@media (max-width: $screen-xs-max) {
}
@media (min-width: $screen-sm) {
}
@media (min-width: $screen-sm) {
}
.collapse-open {
  display: none;
}
.collapse-painel {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.1s, visibility 0.3s, opacity 0.3s;
}
.collapse-open:checked ~ .collapse-painel {
  max-height: 100%;
  opacity: 100;
  visibility: visible;
}
.collapse-list li {
  margin-bottom: 10px;
}
.collapse-list .collapse-btn {
  border: #eaeaea solid 1px;
  background: #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  padding: 5px 10px;
}
.collapse-list .collapse-btn:hover {
  background: #eaeaea;
}
.collapse-list .collapse-inner {
  padding: 10px;
}

/*____________ETIQUETAS____________*/
/*&11*/
.etiqueta {
  border-radius: 5px;
  padding: 6px 15px;
  font-size: 0.9em;
  margin-bottom: 4px;
  display: inline-block;
}
.etiqueta.fina {
  padding: 0px 8px;
  font-size: 0.7em;
}
.etiqueta span {
  font-size: 1em;
}
.etiqueta.rojo {
  background-color: red;
}
.etiqueta.verde {
  background-color: green;
}
.etiqueta.azul {
  background-color: blue;
}
.etiqueta.gris {
  background-color: grey;
}
.etiqueta.destacado {
  background-color: #ff8b00;
}
.etiqueta.rojo.bordebot {
  background-color: red;
  border-bottom: 3px solid #ff5c5c;
}
.etiqueta.verde.bordebot {
  background-color: green;
  border-bottom: 3px solid #6acd7a;
}
.etiqueta.azul.bordebot {
  background-color: blue;
  border-bottom: 3px solid #6a7dcd;
}
.etiqueta.gris.bordebot {
  background-color: grey;
  border-bottom: 3px solid #c3c3c3;
}
.etiqueta.destacado.bordebot {
  background-color: #ff8b00;
  border-bottom: 3px solid #ffd076;
}
.etiqueta.cerrar i:hover {
  color: #000;
}
.etiqueta.cerrar i {
  padding-left: 10px;
}
.etiqueta span:hover {
  color: black;
}

/*____________AVISOS EN PUNTO__________*/
/*&12*/
.aviso-pt {
  width: 11px;
  height: 11px;
  position: absolute;
  top: -6px;
  right: -10px;
  border-radius: 6px;
}
.aviso-pt.num {
  height: auto;
  width: auto;
  border-radius: 4px;
  font-size: 10px;
  font-family: arial;
  text-align: center;
  line-height: 12px;
  padding: 0 3px;
}
.aviso-pt.numbig {
  height: auto;
  width: auto;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial;
  text-align: center;
  line-height: 12px;
  padding: 4px 5px;
  top: -18px;
}

/*________________CAJAS__________________*/
/*&18*/

.caja-mensaje {
  background: #fff1d7;
  border-radius: 10px;
  margin: 8px 0;
  padding: 20px 15px;
  border: 1px solid #cecece;
}
.caja-mensaje2 {
  background: #d9d9d9;
  border-radius: 10px;
  margin: 8px 0;
  padding: 30px 30px;
  border: 8px solid #cfcfcf;
}
.caja-simple {
  padding: 15px;
  border: 1px solid #ccc;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  z-index: 1000;
}

.tarjeta {
  font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial',
    'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22);
  box-sizing: border-box;
  display: block;
  margin: 8px;
  padding: 16px;
  text-align: left;
  word-wrap: break-word;
}
.tarjeta--redondeada {
  border-radius: 8px;
}
.tarjeta--muyredondeada {
  border-radius: 18px;
}
.tarjeta__titulo {
  font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial',
    'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-weight: 400;
  font-size: 20px;
  margin: 4px 0 8px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: #000;
}
.tarjeta__contenido {
  margin: 0;
  font-size: 0.9em;
  line-height: 1.4;
  color: #545454;
}

/*_____________________CAJAS INFO___________________*/
/*&20*/

.sticker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  height: 80px;
  margin-bottom: 10px;
}
.sticker > div:nth-child(1) {
  background-color: rgba(0, 0, 0, 0.1);
  height: 80px;
  width: 80px;
  margin: 0px;
  line-height: 80px;
  text-align: center;
  font-size: 3em;
}
.sticker > div:nth-child(1) img {
  height: 100%;
  margin: 0;
}
.sticker > div:nth-child(2) {
  display: inline-block;
  margin-left: 15px;
}

@media (max-width: 991px) {
  .sticker {
    overflow: hidden;
    height: 55px;
  }
  .sticker > div:nth-child(1) img {
    height: 100%;
    margin: 0;
  }
  .sticker > div:nth-child(2) p {
    line-height: 1.1em;
    font-size: 0.9em;
  }
  .sticker > div:nth-child(1) {
    height: 100%;
    margin: 0;
    font-size: 2.2em;
    width: 55px;
    line-height: 55px;
  }
}

.sticker-violeta {
  background-color: #7266ba;
}
.sticker-azulito {
  background-color: #42a5f6;
}
.sticker-verdito {
  background-color: #7ed320;
}
.sticker-salmon {
  background-color: #f75d81;
}
.sticker-gris {
  background-color: grey;
}

.stitched {
  padding: 20px;
  margin: 10px;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.3em;
  border: 2px dashed #fff;
  border-radius: 10px;
  font-weight: normal;
}
.stitched--rojo {
  background: #ff0030;
  box-shadow: 0 0 0 4px #ff0030, 2px 1px 6px 4px rgba(10, 10, 0, 0.5);
  text-shadow: -1px -1px #aa3030;
}
.stitched--gris {
  background-color: grey;
  box-shadow: 0 0 0 4px grey, 2px 1px 6px 4px rgba(10, 10, 0, 0.5);
  text-shadow: -1px -1px grey;
}
.stitched--verde {
  background-color: #67b700;
  box-shadow: 0 0 0 4px #67b700, 2px 1px 6px 4px rgba(10, 10, 0, 0.5);
  text-shadow: -1px -1px #67b700;
}

/*___Cajas info2___*/
.topcajainfo {
  /* padding:5px; background:#efefef; color:#666;border-bottom:3px solid #fff*/
  height: 10px;
  width: 50px;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
}

.cajainfo {
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  background: #414141;
}
.cajainfo .description {
  font-weight: normal;
  text-align: center;
  margin: 10px -5px;
  color: #fff;
}
.cajainfo strong {
  color: #fff;
  font-size: 1.231em;
}
.cajainfo .icon,
.cajainfo .big-text {
  margin-top: 15px;
  font-size: 36px;
  line-height: 36px;
  height: 36px; /*font-weight:bold;*/
  color: #ffffff;
}

.cajainfo .horizontal {
  text-align: left;
  padding: 10px;
}
.cajainfo .icon-hor {
  float: left;
  margin: 5px 0 2px 0;
  margin-left: 10px;
  font-size: 20px;
  line-height: 20px;
  height: 20px; /*font-weight:bold;*/
  color: #ffffff;
}
.cajainfo .descripcion-hor {
  display: block;
  margin: 2px 0;
  overflow: hidden;
  padding: 0px 0px 0px 10px;
  color: #fff;
  text-align: left;
  line-height: 1.2em;
}
.descripcion-hor strong {
  color: #fff;
  font-size: 1.385em;
}

.cajainfo:before {
  -webkit-transition: width;
  -moz-transition: width;
  -o-transition: width;
  transition: width;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
  width: 5px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
}
.cajainfo:hover:before {
  width: 100%;
}

/*___________________BOTON CON DATOS___________________*/
/*&13*/

.btn-datos {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid #d43f3a;
  background-color: #d9534f;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: #fff;
  cursor: pointer;
  margin-bottom: 4px;
}
.btn-datos:hover {
  background-color: #ae2e31;
  color: white;
}
.btn-datos a span:hover {
  color: white;
}

.btn-datos > span:nth-child(1) {
  margin: 0 5px 0 10px;
  height: 20px;
}
.btn-datos > span i:nth-child(1) {
  margin-right: 5px;
}
.btn-datos > span i:nth-child(1):hover {
  margin-right: 5px;
  color: white;
}
.btn-datos > span:nth-child(2) {
  float: right;
  line-height: 26px;
  padding-left: 5px;
  margin-right: 10px;
  border-left: 1px solid #d43f3a;
}

.btn-datos.fblanco {
  background-color: white;
  color: #4c4c4c;
  border: 1px solid #a8a8a8;
}
.btn-datos.fblanco > span:nth-child(2) {
  border-left: 1px solid #a8a8a8;
}
.btn-datos.fblanco:hover {
  background-color: #dedede;
}
.btn-datos.fblanco > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #4c4c4c;
}

.btn-datos.fgris {
  background-color: #d0d0d0;
  color: #4c4c4c;
  border: 1px solid #a8a8a8;
}
.btn-datos.fgris > span:nth-child(2) {
  border-left: 1px solid #a8a8a8;
}
.btn-datos.fgris:hover {
  background-color: #b8b8b8;
}
.btn-datos.fgris > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #4c4c4c;
}

.btn-datos.fverde {
  background-color: #4fd955;
  color: #fff;
  border: 1px solid #36b025;
}
.btn-datos.fverde > span:nth-child(2) {
  border-left: 1px solid #36b025;
}
.btn-datos.fverde:hover {
  background-color: #43bd48;
}
.btn-datos.fverde > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #fff;
}

.btn-datos.fazul {
  background-color: #4f7cd9;
  color: #fff;
  border: 1px solid #255db0;
}
.btn-datos.fazul > span:nth-child(2) {
  border-left: 1px solid #255db0;
}
.btn-datos.fazul:hover {
  background-color: #466dbd;
}
.btn-datos.fazul > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #fff;
}

.btn-datos.fnaranja {
  background-color: #d18428;
  color: #fff;
  border: 1px solid #ae7026;
}
.btn-datos.fnaranja > span:nth-child(2) {
  border-left: 1px solid #ae7026;
}
.btn-datos.fnaranja:hover {
  background-color: #be761f;
}
.btn-datos.fnaranja > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #fff;
}

.btn-datos.fnegro {
  background-color: #4c4c4c;
  color: #fff;
  border: 1px solid #323232;
}
.btn-datos.fnegro > span:nth-child(2) {
  border-left: 1px solid #323232;
}
.btn-datos.fnegro:hover {
  background-color: #313131;
}
.btn-datos.fnegro > span i:nth-child(1):hover {
  margin-right: 5px;
  color: #fff;
}

/*&22*/
.btn-ico {
  border-radius: 5px;
  overflow: hidden;
  color: #535353;
  background-color: #d5d5d5;
  padding: 5px 0px;
  font-size: 0.8em;
  vertical-align: middle;
  display: inline-block;
}
.btn-ico > span > span i:nth-child(1):hover {
  color: #fff;
}
.btn-ico > span > span:nth-child(1) {
  background-color: #535353;
  color: white;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}
.btn-ico > span > span:nth-child(2) {
  position: relative;
  line-height: 1.2em;
  padding: 3px 10px;
}
.ico-fder::after {
  content: '\25b6';
  position: absolute;
  left: -4px;
  top: 3px;
}
.btn-ico.f-rojo {
  background-color: #e45737;
}
.btn-ico.f-amarillo {
  background-color: #e4d637;
}
.btn-ico.f-azul {
  background-color: #6db1e0;
}
.btn-ico.f-verde {
  background-color: #b9e06d;
}
.btn-ico:hover {
  background-color: grey;
  color: #535353;
}

/*_____________________MENU DESPLEGABLE______________________*/
/*&14*/

.triangulo {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 0 2px 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.link-dropdownmenu {
  color: grey;
}
.link-dropdownmenu:hover {
  color: black;
}
.dropdown-menu {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1000;
  display: none;
  min-width: auto;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu-opc {
  line-height: 1em !important;
  padding: 5px !important;
  display: block;
}
.dropdown-menu-enlace {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu-enlace:hover {
  background-color: #d8d8d8;
}
.dropdown-menu-enlace.activo {
  background-color: #e6e6e6;
}
.dropdown-menu-imagen {
  vertical-align: baseline;
}
.dropdown-menu-imagen--size {
  width: 30px;
  height: 25px;
}

/*______________________PASOS______________________*/
/*&15*/
.caja-pasos {
  position: relative;
}
.pt-pasos {
  position: absolute;
  top: -6px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.pt-pasos__paso {
  background-color: grey;
  color: white;
  border-radius: 15px;
  font-size: 0.9em;
  padding: 0 9px;
}
.linea-tiempo {
  background-color: grey;
  height: 12px;
  border-radius: 20px;
}

/*____________________BREADCRUMB___________________*/
/*&16*/
.breadcrumb {
  color: grey;
}
.breadcrumb a {
  display: inline-block;
}
.breadcrumb a:after {
  content: '/';
  vertical-align: top;
  display: inline-block;
  font-weight: bolder;
  font-style: normal;
  font-size: 15px;
  margin: 0 8px 0 8px;
  -webkit-font-smoothing: antialiased;
}
.breadcrumb a:last-child:after {
  content: '';
}
.breadcrumb a:last-child {
  color: #ff8b00;
}

/*______________________CARGA______________________*/
/*&17*/

/*Precarga en linea------------------------------*/
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #acece6;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}
.progress .indeterminate {
  background-color: #26a69a;
}
.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

/*Precarga circular-----------------------------*/
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-wrapper.small {
  width: 36px;
  height: 36px;
}
.preloader-wrapper.big {
  width: 64px;
  height: 64px;
}
.preloader-wrapper.active {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}
@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a;
}
.spinner-blue,
.spinner-blue-only {
  border-color: #4285f4;
}
.spinner-red,
.spinner-red-only {
  border-color: #db4437;
}
.spinner-yellow,
.spinner-yellow-only {
  border-color: #f4b400;
}
.spinner-green,
.spinner-green-only {
  border-color: #0f9d58;
}
.active .spinner-layer.spinner-blue {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-red {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-yellow {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-green {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}
@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.gap-patch .circle {
  width: 1000%;
  left: -450%;
}
.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg);
}
.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg);
}
.active .circle-clipper.left .circle {
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .circle-clipper.right .circle {
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
  }
}
@keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}
@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
  }
}
@keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

/*Video responsive*/
/*&21*/
.video-container {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  position: relative;
}
.video-container iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/*________________________COMENTARIOS_____________________*/
/*&25*/
.coment {
  margin: 15px;
}

.coment__caja {
  border-bottom: 1px dotted #ddd;
}
.coment > div {
  border-bottom: 0 dotted white;
}

.coment__tit {
  font-size: 1em;
}
.coment__tit span {
  color: #c3c3c3;
}

.coment__login {
  padding: 10px 0;
  margin: 10px 0;
  background-color: #e4e4e4;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
}
.coment__login a {
  color: #00aeff;
}

.coment__cajainput {
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.coment__cajainputavatar {
  margin: 0 10px 0 0;
}
.coment__avatarinput {
  width: 50px;
}
.coment__botonenviar {
  height: 3.2em;
  cursor: pointer;
}
.coment__input {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  margin-bottom: 0;
  font-size: 1em;
}

.coment__caja {
  padding: 5px 0;
  margin: 5px 0;
}

.coment__avatar {
  float: left;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .coment__avatar {
    margin: 0 5px 0 0;
  }
}

.coment__avatar img {
  width: 50px;
  max-width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .coment__avatar img {
    width: 15px;
  }
}

.coment__datos {
  padding: 0 0 0 75px;
}
@media (max-width: 768px) {
  .coment__datos {
    padding: 0 0 0 20px;
  }
}

.coment__nombre {
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
}
@media (max-width: 768px) {
  .coment__nombre {
    font-size: 0.9em;
  }
}

.coment__fecha {
  font-size: 0.7em;
}
.coment__texto {
  font-size: 0.9em;
  line-height: 1.3em;
}
@media (max-width: 768px) {
  .coment__texto {
    font-size: 0.8em;
    line-height: 1.3em;
  }
}
.coment__opcion {
  font-size: 0.8em;
}

/*______________________Zona politica privacidad_____________________*/

.zona-mensa {
  padding: 5px 10px;
  border: 1px solid #c6c6c6;
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}
.switch_div {
  padding-bottom: 10px;
}
.degradado-gris {
  background: rgba(254, 254, 254, 1);
  background: -moz-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(254, 254, 254, 1)),
    color-stop(100%, rgba(226, 226, 226, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(226, 226, 226, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(254, 254, 254, 1) 0%,
    rgba(226, 226, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

/*___________________________Zona calendario_________________________*/

.calendar {
  width: 100%;
  margin: 0 auto;
}

.calendar caption {
  margin: 0;
  padding: 0;
  /*background: #2c699e;*/
  /*color: #fff;*/
  font: normal 1.154em /*1.231em Tahoma,*/ Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 5px 0;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #e3e3e3;
}

.calendar caption a,
.calendar caption a:hover {
  color: #fff;
  text-decoration: none;
}

.calendar th {
  font: bold 0.923em Tahoma, Arial, Helvetica, sans-serif;
  /*color: #b7e6ff;*/
  /*background: #428bca;*/
  text-decoration: none;
  border: 1px solid #e3e3e3;
  border-bottom: 1px solid grey;
  padding: 4px;
  cursor: help;
}

.calendar .seleccion,
.calendar td.seleccion a,
.calendar td.seleccion a:link,
.calendar td.seleccion a:visited {
  color: #ffffff;
  background: #449d44;
  font-weight: bold;
  /*-moz-box-shadow:0px 0px 18px #acd0e5 inset; -webkit-box-shadow:0px 0px 18px #acd0e5 inset; box-shadow:0px 0px 18px #acd0e5 inset;*/
}

.calendar .hoy,
.calendar td.hoy a,
.calendar td.hoy a:link,
.calendar td.hoy a:visited {
  color: #fff;
  font-weight: bold;
  background: #969696;
  /*-moz-box-shadow:0px 0px 18px #acd0e5 inset; -webkit-box-shadow:0px 0px 18px #acd0e5 inset; box-shadow:0px 0px 18px #acd0e5 inset;*/
}

.calendar .finde,
.calendar td.finde a,
.calendar td.finde a:link,
.calendar td.finde a:visited {
  color: #000000;
  font-weight: normal;
  background: #fcf8dd;
  /*-moz-box-shadow:0px 0px 18px #acd0e5 inset; -webkit-box-shadow:0px 0px 18px #acd0e5 inset; box-shadow:0px 0px 18px #acd0e5 inset;*/
}

.calendar td {
  font-size: 0.923em;
  padding: 0px;
  text-align: center;
  background: #fff;
  border: 1px solid #e3e3e3;
  color: #333333;
  height: 30px;
}

.calendar td a {
  text-decoration: none;
  font-weight: normal;
  display: block;
  height: 30px;
  padding-top: 5px;
  cursor: pointer;
}
.calendar td a:link,
.calendar td a:visited {
  color: #333333;
  background: #ffffff;
}
.calendar td a:hover,
.calendar td a:active,
.calendar td a.activa {
  color: #333333;
  background: #e6e6e6;
  position: relative;
  -moz-box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1) inset;
}

/*.calendar td a[title]:hover:after {
  content: attr(title);
  padding: 4px 8px;
  color: #333;
  position: absolute;
  right: 0;
  top: -30px;
  white-space: nowrap;
  z-index: 20px;
  
  background:#ccc;
  display:block;
  max-width:200px;
}

@media (max-width: 767px) {
  .calendar td a[title]:hover:after {display:none}
}*/

/*__________________________PROGRESS_BARS_________________________*/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
} /*es para marcar un contenido como oculto y que sÃ³lo estÃ© disponible para los lectores ("screen readers" en inglÃ©s, de ahÃ­ el nombre de la clase CSS)*/

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.ico-progress {
  display: inline-block;
  color: #333;
  margin-right: 5px;
}
.boxico {
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-right: 3px;
  background: inherit;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress.big {
  height: 30px;
}
.progress.small {
  height: 10px;
  border-radius: 2px;
}
.progress.mini {
  height: 5px;
  border-radius: 1px;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #0c8dcc;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-bar.big {
  line-height: 30px;
}
.progress-bar.small {
  line-height: 10px;
  border-radius: 0px;
}
.progress-bar.mini {
  line-height: 5px;
  border-radius: 0px;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar[aria-valuenow='1'],
.progress-bar[aria-valuenow='2'] {
  min-width: 30px;
}
.progress-bar[aria-valuenow='0'] {
  min-width: 30px;
  color: #777;
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar-success {
  background-color: #5cb300;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
  background-color: #2dabc4;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-warning {
  background-color: #ea5811;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-danger {
  background-color: #da3436;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

/*=========================================
  21. Ajuste vertical web a pantalla
  (consigue que el pie/footer estÃ© siempre
  ajustado abajo)
==========================================*/

.ajustalalto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}
.ajustalalto-contenido {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.ajustalalto-pie {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/*=========================
  22. Colores (FONDOS) 
=========================*/

/*&01 en custom.css*/

/*Colores generales de FONDO*/
.destacado {
  background-color: #575757;
}

.negro {
  background-color: #000000;
}
.blanco {
  background-color: #ffffff;
}
.gris {
  background-color: grey;
}

.naranja {
  background-color: #ff8400;
}
.verde {
  background-color: #38a640;
}
.rojo {
  background-color: #e84e40;
}

.grisclaro {
  background-color: #d0d0d0;
}
.grisclaro2 {
  background-color: #f8f8f8;
}
.grisclaro3 {
  background-color: #fbfbfb;
}
.grisoscuro {
  background-color: #595959;
}
.grisoscuro2 {
  background-color: #272727;
}

.azul {
  background-color: #106ac1;
}
.violeta {
  background-color: #7266ba;
}
.azulito {
  background-color: #42a5f6;
}
.azulclaro {
  background-color: #bcdcf6;
}
.verdito {
  background-color: #7ed320;
}
.verdoso {
  background-color: #419b97;
}
.salmon {
  background-color: #f75d81;
}
